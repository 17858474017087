.form-group .field-tooltip {
  display: inline-block;
  margin-left: 6px;

  &.tooltip-icon {
    font-size: .8em;
    position: inherit;

    &:hover {
      .tooltip-body {
        display: block;
      }
    }
  }

  .tooltip-wrapper {
    position: absolute;
    width: 0;
  }

  .tooltip-body {
    display: none;
    position: relative;
    width: 300px;
    max-width: 300px;
    top: 20px;
    left: -155px;
    padding: 8px;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 1.1em;
    line-height: 1.2em;
    background: #fff;
    border: 1px solid @form-field-border-color;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-bottom: 8px solid @form-field-border-color;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -6px;
      width: 0;
      height: 0;
      border-bottom: 6px solid #fff;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
}
