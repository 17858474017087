header {
  .container {
    display: flex;
    justify-content: space-between;

    &:before,
    &:after {
      display: none;
    }

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .logo {
    display: inline-block;
    line-height: @header-height;
    align-self: center;
  }

  .nav-wrap {
    display: flex;
    align-items: stretch;
  }

  nav {
    display: inline-block;
    padding: 0 20px;
    margin-right: 30px;

    .item {
      position: relative;
      display: inline-block;
      font-family: @font-family-sans;
      font-weight: 400;
      font-size: 20px;
      line-height: @header-height;
      color: @brand-dark-gray2;
      padding: 0 10px;
      margin-right: 10px;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 5px;
        background: transparent;
        transition: @transition-default;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      &.active,
      &:hover {
        &:after {
          background: @brand-primary;
        }
      }
    }
  }

  .dropdown {
    align-self: center;

    @media (max-width: 400px) {
      margin-top: 10px;
      margin-right: 0;
      text-align: left;
    }
  }
}
