.alert {
  margin: 10px auto;
  padding: 40px 20px;
  max-width: 90%;
  text-align: center;
  font-size: 18px;

  &-notice {
    background-color: @brand-blue;
    color: #fff;
  }

  &-success {
    border: none;
    color: @brand-dark-gray2;
    background-color: @color-success;
  }

  &-error {
    border: none;
    color: @brand-dark-gray2;
    background-color: #ef9875;
  }
}
