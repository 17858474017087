.share-campaign-container {
  background-color: #fff;
  padding: 20px;

  .share-campaign {
    width: 66%;
    margin: 0 auto;
    padding: @grid-gutter-width;
    background-color: @brand-light-gray1;

    input {
      width: 100%;
      margin-bottom: 6px;
      cursor: text;
    }

    textarea {
      font-weight: inherit;
      font-size: inherit;
      color: inherit;
      resize: none;
    }

    .title {
      margin: 0;
    }

    + .share-campaign {
      margin-top: @grid-gutter-width;
    }
  }
}
