div.jsoneditor-menu {
  width: 100%;
  height: 35px;
  padding: 2px;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  background-color: #c1bfbf;
}

div.jsoneditor-tree {
  background: #fff;
}

div.jsoneditor-outer {
  border: 1px solid #f1ecec;
}

div.jsoneditor-menu > button,
div.jsoneditor-menu > div.jsoneditor-modes > button {
  width: 26px;
  height: 26px;
  margin: 2px;
  padding: 0;
  border-radius: 2px;
  border: 1px solid transparent;
  background: transparent url('@{icons-path}/jsoneditor-icons.svg');
  color: white;
  opacity: 0.8;

  font-family: arial, sans-serif;
  font-size: 10pt;

  float: left;
}

div.jsoneditor-menu > button:hover,
div.jsoneditor-menu > div.jsoneditor-modes > button:hover {
  background-color: rgba(255,255,255,0.2);
  border: 1px solid rgba(255,255,255,0.4);
}
div.jsoneditor-menu > button:focus,
div.jsoneditor-menu > button:active,
div.jsoneditor-menu > div.jsoneditor-modes > button:focus,
div.jsoneditor-menu > div.jsoneditor-modes > button:active {
  background-color: rgba(255,255,255,0.3);
}
div.jsoneditor-menu > button:disabled,
div.jsoneditor-menu > div.jsoneditor-modes > button:disabled {
  opacity: 0.5;
}

div.jsoneditor-menu > button.jsoneditor-collapse-all {
  background-position: 0 -96px;
}
div.jsoneditor-menu > button.jsoneditor-expand-all {
  background-position: 0 -120px;
}
div.jsoneditor-menu > button.jsoneditor-sort {
  background-position: -120px -96px;
}
div.jsoneditor-menu > button.jsoneditor-transform {
  background-position: -144px -96px;
}
div.jsoneditor.jsoneditor-mode-view > div.jsoneditor-menu > button.jsoneditor-sort,
div.jsoneditor.jsoneditor-mode-form > div.jsoneditor-menu > button.jsoneditor-sort,
div.jsoneditor.jsoneditor-mode-view > div.jsoneditor-menu > button.jsoneditor-transform,
div.jsoneditor.jsoneditor-mode-form > div.jsoneditor-menu > button.jsoneditor-transform {
  display: none;
}
div.jsoneditor-menu > button.jsoneditor-undo {
  background-position: -24px -96px;
}
div.jsoneditor-menu > button.jsoneditor-undo:disabled {
  background-position: -24px -120px;
}
div.jsoneditor-menu > button.jsoneditor-redo {
  background-position: -48px -96px;
}
div.jsoneditor-menu > button.jsoneditor-redo:disabled {
  background-position: -48px -120px;
}
div.jsoneditor-menu > button.jsoneditor-compact {
  background-position: -72px -96px;
}
div.jsoneditor-menu > button.jsoneditor-format {
  background-position: -72px -120px;
}
div.jsoneditor-menu > button.jsoneditor-repair {
  background-position: -96px -96px;
}

div.jsoneditor-menu > div.jsoneditor-modes {
  display: inline-block;
  float: left;
}

div.jsoneditor-menu > div.jsoneditor-modes > button {
  background-image: none;
  width: auto;
  padding-left: 6px;
  padding-right: 6px;
}

div.jsoneditor-menu > button.jsoneditor-separator,
div.jsoneditor-menu > div.jsoneditor-modes > button.jsoneditor-separator {
  margin-left: 10px;
}

div.jsoneditor-menu a {
  font-family: arial, sans-serif;
  font-size: 10pt;
  color: white;
  opacity: 0.8;
  vertical-align: middle;
}

div.jsoneditor-menu a:hover {
  opacity: 1;
}

div.jsoneditor-menu a.jsoneditor-poweredBy {
  display: none;
}
