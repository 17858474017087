.data-mapper {
  background-color: #fff;

  .current-field {
    content: '';
    position: absolute;
    display: block;
    opacity: 0;
    top: -5px;
    bottom: -5px;
    left: -10px;
    right: -10px;
    border-radius: 4px;
    border: 2px dashed #04ca9a;
    z-index: 1;
  }

  .actions {
    position: absolute;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -3px;
    bottom: -3px;
    left: 60%;
    right: -8px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    background-color: #04ca9a;
    border-radius: 0 4px 4px 0;
    z-index: 2;
  }

  .action-btn {
    border: 2px solid #fff;
    border-radius: 4px;
    margin: 0 5px;
    padding: 10px;
    min-width: 92px;
    text-align: center;

    &:hover {
      background: #01bd8f;
    }
  }

  .form-name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    color: #605c58;
    padding: 0 20px;
  }

  .field-wrap {
    position: relative;
    cursor: pointer;

    &:before {
      .data-mapper.current-field;
    }

    &.current {
      &:before {
        opacity: 1;
      }
    }

    &.ready {
      .action-ready {
        visibility: visible;
      }
    }

    &.mapped {
      .input,
      input,
      textarea,
      select,
      .image-uploader,
      .google-maps-container {
        outline: 2px solid #04ca9a;
      }
    }

    &.matched {
      .action-matched {
        visibility: visible;
      }
    }

    &.pending {
      .action-pending {
        visibility: visible;
      }
    }

    &.active {
      &:before {
        opacity: 1;
      }

      .action-active {
        visibility: visible;
      }
    }

    &.inactive {
      opacity: .3;
      cursor: default;
    }
  }

  .campaign-form {
    padding: 20px;
    max-height: ~"calc(100vh - 170px)";
    margin: 0;
    overflow-y: scroll;

    .module-list {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      border-radius: 4px;
    }

    .module-title {
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 5px;
      color: @brand-primary;
      text-transform: uppercase;
    }

    .module {
      padding: 5px 20px;
      background-color: #f0f0f0;
      border: 1px solid #605C58;
      margin-bottom: 15px;
      border-radius: 4px;
    }

    .module-list .module:last-child {
      border-bottom: none;
    }
  }
}

.revisions-list {
  margin-top: 30px;
  max-height: 80vh;
  overflow-y: scroll;
}
.revisions-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #4d4d4d;
  border-radius: 3px;
  padding: 10px 15px;
  color: #4d4d4d;
  font-size: 15px;
  letter-spacing: 0.1px;
  margin: 0 15px 10px 20px;
  strong {
    font-weight: 600;
    letter-spacing: 0.08px;
  }
  .action-button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
  }
}
