.header-image-container {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.header-image {
  .img-cover();
}
