/* ContextMenu - main menu */

div.jsoneditor-contextmenu-root {
  position: relative;
  width: 0;
  height: 0;
}

div.jsoneditor-contextmenu {
  position: absolute;
  box-sizing: content-box;
  z-index: 99999;
}

div.jsoneditor-contextmenu ul,
div.jsoneditor-contextmenu li {
  box-sizing: content-box;
  position: relative;
}

div.jsoneditor-contextmenu ul {
  position: relative;
  left: 0;
  top: 0;
  width: 128px;

  background: white;
  border: 1px solid #d3d3d3;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3);

  list-style: none;
  margin: 0;
  padding: 0;
}

div.jsoneditor-contextmenu ul li button {
  position: relative;
  padding: 0 4px 0 0;
  margin: 0;
  width: 128px;
  height: auto;
  border: none;
  cursor: pointer;
  color: #4d4d4d;
  background: transparent;

  font-size: 10pt;
  font-family: arial, sans-serif;

  box-sizing: border-box;

  text-align: left;
}

/* Fix button padding in firefox */
div.jsoneditor-contextmenu ul li button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

div.jsoneditor-contextmenu ul li button:hover,
div.jsoneditor-contextmenu ul li button:focus {
  color: #1a1a1a;
  background-color: #f5f5f5;
  outline: none;
}

div.jsoneditor-contextmenu ul li button.jsoneditor-default {
  width: 96px; /* 128px - 32px */
}

div.jsoneditor-contextmenu ul li button.jsoneditor-expand {
  float: right;
  width: 32px;
  height: 24px;
  border-left: 1px solid #e5e5e5;
}

div.jsoneditor-contextmenu div.jsoneditor-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
  margin: 0;
  background-image: url('@{icons-path}/jsoneditor-icons.svg');
}

div.jsoneditor-contextmenu ul li ul div.jsoneditor-icon {
  margin-left: 24px;
}

div.jsoneditor-contextmenu div.jsoneditor-text {
  padding: 4px 0 4px 24px;
  word-wrap: break-word;
}

div.jsoneditor-contextmenu div.jsoneditor-text.jsoneditor-right-margin {
  padding-right: 24px;
}

div.jsoneditor-contextmenu ul li button div.jsoneditor-expand {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url('@{icons-path}/jsoneditor-icons.svg') 0 -72px;
}

div.jsoneditor-contextmenu div.jsoneditor-separator {
  height: 0;
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
  margin-top: 5px;
}

div.jsoneditor-contextmenu button.jsoneditor-remove > div.jsoneditor-icon {
  background-position: -24px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-append > div.jsoneditor-icon {
  background-position: 0 0;
}

div.jsoneditor-contextmenu button.jsoneditor-insert > div.jsoneditor-icon {
  background-position: 0 0;
}

div.jsoneditor-contextmenu button.jsoneditor-duplicate > div.jsoneditor-icon {
  background-position: -48px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-sort-asc > div.jsoneditor-icon {
  background-position: -168px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-sort-desc > div.jsoneditor-icon {
  background-position: -192px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-transform > div.jsoneditor-icon {
  background-position: -216px 0;
}

/* ContextMenu - sub menu */

div.jsoneditor-contextmenu ul li button.jsoneditor-selected,
div.jsoneditor-contextmenu ul li button.jsoneditor-selected:hover,
div.jsoneditor-contextmenu ul li button.jsoneditor-selected:focus {
  color: white;
  background-color: #ee422e;
}

div.jsoneditor-contextmenu ul li {
  overflow: hidden;
}

div.jsoneditor-contextmenu ul li ul {
  display: none;
  position: relative;
  left: -10px;
  top: 0;

  border: none;
  box-shadow: inset 0 0 10px rgba(128, 128, 128, 0.5);
  padding: 0 10px;

  /* TODO: transition is not supported on IE8-9 */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

div.jsoneditor-contextmenu ul li.jsoneditor-selected ul {
}

div.jsoneditor-contextmenu ul li ul li button {
  padding-left: 24px;
  animation: all ease-in-out 1s;
}

div.jsoneditor-contextmenu ul li ul li button:hover,
div.jsoneditor-contextmenu ul li ul li button:focus {
  background-color: #f5f5f5;
}

div.jsoneditor-contextmenu button.jsoneditor-type-string > div.jsoneditor-icon {
  background-position: -144px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-auto > div.jsoneditor-icon {
  background-position: -120px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-object > div.jsoneditor-icon {
  background-position: -72px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-array > div.jsoneditor-icon {
  background-position: -96px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-modes > div.jsoneditor-icon {
  background-image: none;
  width: 6px;
}


/* pico modal styling */

.jsoneditor-modal-overlay {
  position: absolute !important;

  background: rgb(1,1,1) !important;
  opacity: 0.3 !important;
}

.jsoneditor-modal {
  position: absolute !important;
  max-width: 95% !important;
  width: auto !important;

  border-radius: 2px !important;
  padding: 45px 15px 15px 15px !important;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3) !important;

  color: #4d4d4d;
  line-height: 1.3em;
}

.jsoneditor-modal.jsoneditor-modal-transform {
  width: 600px !important;
}

.jsoneditor-modal .pico-modal-header {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;

  font-family: arial, sans-serif;
  font-size: 11pt;

  background: #3883fa;
  color: white;
}

.jsoneditor-modal table {
  width: 100%;
}

.jsoneditor-modal table th,
.jsoneditor-modal table td {
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  color: #4d4d4d;
  border-spacing: 0;
  border-collapse: collapse;
}

.jsoneditor-modal table td {
  padding: 3px 0;
}

.jsoneditor-modal p:first-child {
  margin-top: 0;
}

.jsoneditor-modal a {
  color: #3883fa;
}

.jsoneditor-modal table td:first-child {
}

.jsoneditor-modal .jsoneditor-jmespath-block {
  margin-bottom: 10px;
}

.jsoneditor-modal table td.jsoneditor-modal-input {
  text-align: right;
  padding-right: 0;
  white-space: nowrap;
}

.jsoneditor-modal table td.jsoneditor-modal-actions {
  padding-top: 15px;
}

.jsoneditor-modal .pico-close {
  background: none !important;
  font-size: 24px !important;
  top: 7px !important;
  right: 7px !important;
  color: white;
}

.jsoneditor-modal select,
.jsoneditor-modal textarea,
.jsoneditor-modal input,
.jsoneditor-modal #query {
  background: #ffffff;
  border: 1px solid #d3d3d3;
  color: #4d4d4d;
  border-radius: 3px;
  padding: 4px;
}

.jsoneditor-modal,
.jsoneditor-modal table td,
.jsoneditor-modal table th,
.jsoneditor-modal select,
.jsoneditor-modal option,
.jsoneditor-modal textarea,
.jsoneditor-modal input,
.jsoneditor-modal #query {
  font-size: 10.5pt;
  font-family: arial, sans-serif;
}

.jsoneditor-modal table th {
  vertical-align: middle;
}

.jsoneditor-modal #query,
.jsoneditor-modal .jsoneditor-transform-preview {
  font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
  font-size: 10pt;
}

.jsoneditor-modal input[type="button"],
.jsoneditor-modal input[type="submit"] {
  background: #f5f5f5;
  padding: 4px 20px;
}

.jsoneditor-modal select,
.jsoneditor-modal input {
  cursor: pointer;
}

.jsoneditor-modal input {
  padding: 4px;
}

.jsoneditor-modal input[type="text"] {
  cursor: inherit;
}

.jsoneditor-modal input[disabled] {
  background: #d3d3d3;
  color: #808080;
}

.jsoneditor-modal .jsoneditor-select-wrapper {
  position: relative;
  display: inline-block;
}

.jsoneditor-modal .jsoneditor-select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #666;
  position: absolute;
  right: 8px;
  top: 14px;
  pointer-events: none;
}

.jsoneditor-modal select {
  padding: 3px 24px 3px 10px;
  min-width: 180px;
  max-width: 350px;

  -webkit-appearance:none;
  -moz-appearance:none;
  appearance: none;

  text-indent: 0;
  text-overflow: "";
  font-size: 10pt;
  line-height: 1.5em;
}

.jsoneditor-modal select::-ms-expand {
    display: none;
}

.jsoneditor-modal .jsoneditor-button-group input {
  padding: 4px 10px;
  margin: 0;
  border-radius: 0;
  border-left-style: none;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-first {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left-style: solid;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-asc input.jsoneditor-button-asc,
.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
  background: #3883fa;
  border-color: #3883fa;
  color: white;
}

.jsoneditor-modal #query,
.jsoneditor-modal .jsoneditor-transform-preview {
  width: 100%;
  box-sizing: border-box;
}

.jsoneditor-modal .jsoneditor-transform-preview {
  background: #f5f5f5;
  height: 200px;
}

.jsoneditor-modal .jsoneditor-transform-preview.jsoneditor-error {
  color: #ee422e;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard {
  line-height: 1.2em;
  width: 100%;
  padding: 0;
  border-radius: 3px;
}

.jsoneditor-modal .jsoneditor-jmespath-label {
  font-weight: bold;
  color: dodgerblue;
  margin-top: 20px;
  margin-bottom: 5px;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-table {
  width: 100%;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-label {
  font-style: italic;
  margin: 4px 0 2px 0;
}

.jsoneditor-modal .jsoneditor-inline {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
}

.jsoneditor-modal .jsoneditor-inline:not(:last-child) {
  padding-right: 2px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter {
  display: flex;
  flex-wrap: wrap;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-field {
  width: 180px;
}
.jsoneditor-modal .jsoneditor-jmespath-filter-relation {
  width: 100px;
}
.jsoneditor-modal .jsoneditor-jmespath-filter-value {
  min-width: 180px;
  flex: 1;
}

.jsoneditor-modal .jsoneditor-jmespath-sort-field {
  width: 170px;
}
.jsoneditor-modal .jsoneditor-jmespath-sort-order {
  width: 150px;
}

.jsoneditor-modal .jsoneditor-jmespath-select-fields {
  width: 100%;
}

.jsoneditor-modal .selectr-selected {
  border-color: #d3d3d3;
  padding: 4px 28px 4px 8px;
}

.jsoneditor-modal .selectr-selected .selectr-tag {
  background-color: #3883fa;
  border-radius: 5px;
}
