.trigger-configuration {
  min-height: 300px;
  background-color: #fff;
  width: 60%;
  min-width: 700px;
  opacity: 1;
  padding: 20px;
  position: relative;
  overflow: auto;

  span.closing {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #d01c34;
    font-size: 16px;
    cursor: pointer;
  }

  .view-title {
    color: @brand-dark-gray2;
  }

  .field-choice {
    padding: 10px;
    background-color: #f1f1f1;
    height: 50px;
    line-height: 30px;
    margin-top: 10px;
    cursor: pointer;
  }

  .field-choice:hover {
    background-color: #dedede;
  }

  .field-configuration {
    padding: 10px;
    background-color: #d0d0d0;
    line-height: 30px;
    margin-top: 10px;

    span {
      font-size: 18px;
    }

    input[type=radio] {
      top: 4px;
    }

    input[type=text] {
      margin-left: 10px;
      width: 40%;
      padding-left: 5px;
    }

    select {
      display: block;
      width: 40%;
      font-size: 14px;
      padding: 5px;
      background-color: #fff;
      height: 34px;
    }
  }

  .navigation-button {
    margin-top: 20px;
  }
}
