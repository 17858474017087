.submission-data {
  .submission-count {
    font-size: 18px;

    > span {
      color: @brand-blue;
    }
  }

  .submission-info {
    display: flex;
    padding-bottom: 10px;

    table {
      width: 50%;
      background-color: #e8ecf9;

      &:first-child {
        margin-right: 1px;
        border-radius: 7px 0px 0px 7px;
      }
      &:last-child {
        margin-left: 1px;
        border-radius: 0px 7px 7px 0px;
      }

      tr {
        &:first-child td {
          padding-top: 12px;
        }

        &:last-child td {
          padding-bottom: 12px;
        }

        td {
          padding-left: 15px;
          padding-bottom: 7px;

          &:first-child {
            width: 20%;
            font-weight: bold;
          }

          &:last-child {
            width: 80%;
          }
        }
      }
    }
  }

  .submission-info.list-view {
    table {
      background-color: transparent !important;

      tr td {
        padding-left: 0px;
      }
    }
  }

  .info-bar {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #fff;

    .submissions-count {
      margin: 0;
    }
  }

  .short-content,
  .long-content {
    white-space: pre-wrap;
  }

  .image-table {
    height: 27rem;
  }

  #json-table {
    width: 100%;
  }

  .submitted-data {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #bcbab8;

    .user-entry {
      margin-top: 20px;

      .list-unstyled {
        list-style: none;
        padding: 0;
      }
    }

    .submitted-content {
      width: 100%;

      .field-wrapper {
        border-top: 1px solid @form-field-border-color;

        &:first-of-type {
          border-top: none;
        }
      }
      .field {
        padding: 10px;
      }
      .field-name {
        width: 250px;
        font-weight: bold;
        padding: 10px;
      }
      mark {
        background: #ff0;
        padding: 0;
      }
    }

    .finalise {
      top: 0;
      left: 0;
      font-size: 20px;
      color: grey;
      margin-top: 0;
      margin-right: 10px;
    }

    .data-table.full-border {
      table {
        td, th {
          cursor: default;
          border: 1px solid #dedbd7;
        }
      }
    }
  }
  .revision-div {
    background: #fff;
    border-radius: 3px;
    padding: 10px 0;
  }

  .toggle-btn-container {
    text-align: right;
    padding: 10px 0;
  }

  .campaign-submission-title {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    .sth, .std {
      color: #4d4d4d;
      font-family: @font-family-sans;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.13px;
      min-width: 92px;
      margin-right: 10px;
    }
    .sth {
      font-weight: 600;
    }
    .str {
      padding-right: 30px;
    }
  }
  .collapse-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .collapse-action {
    button {
      text-decoration: none;
      border-radius: 4px;

      &:focus {
        outline: none;
        outline-offset: none;
      }
      &:hover {
        background-color: #eeeeee;
      }

      &:before {
        content: 'Show';
        font-size: 14px;
        color: #4d6cef;
      }

      &.active {
        &:before {
          content: 'Hide';
        }
      }
    }
  }

  .submissions-list {
    padding: 10px 20px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;

    .glyphicon-refresh {
      color: grey;
    }
  }

  .paginator-tfooter {
    margin-top: 0;
    padding-bottom: 10px;
  }
}

.submissions-count {
  color: #494e57;
  font-size: 22px;
  font-weight: 400;
  margin-top: -10px;
  margin-bottom: 20px;
}

.acce-collapse {
  .collapse {
    padding-top: 10px;
    background-color: #e8ecf9;
    margin: 30px -20px -60px -20px;
  }

  .loading {
    background-color: #fff;
  }

  .card-body {
    margin: 0 20px 50px 20px;
    padding-bottom: 10px;
  }

  .data-table table {
    margin-bottom: 10px;
  }
}

.submissions {
  .data-table table {
    th {
      background-color: #f0f0f0;
      font-size: 13px;
      letter-spacing: .5px;
      font-weight: 400;
    }

    tr {
      font-size: 15px;
      box-shadow: none;
      border: 1px solid #dedede;

      &:nth-child(2n+1) {
        background-color: #fff;
      }

      &:hover:not(.header),
      &.selected {
        background-color: #f0f0f0;
        color: #333;

        .revision-subtitle {
          color: #666;
        }
      }

      &.selected {
        .campaign:before {
          content: '';
          position: absolute;
          left: -1px;
          top: -1px;
          bottom: -1px;
          width: 5px;
          background-color: #4d6cef;
        }
      }
    }

    td {
      font-weight: 300;

      &.name {
        color: #494e57;
        font-weight: 400;
      }
    }

    .action-button {
      font-size: 1em;
      margin: -16px 8px 0;

      &.glyphicon-trash {
        color: #0b0a0a;

        &.active {
          background-color: #f33f17;
          color: #fff;
          transition: none;

          &:hover {
            background-color: darken(#f33f17, 20%);
          }
        }
      }
    }
  }
}

.submissions-layout-mode {
  display: flow-root;
  padding-bottom: 10px;

  .right {
    float: right;
  }

  span {
    vertical-align: -webkit-baseline-middle;
  }
  select {
    width: 100px;
    background: #fff url('@{icons-path}/select-arrow.png') no-repeat center right 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.submission-details {
  .user-description,
  .short-content,
  .long-content {
    white-space: pre-wrap;
  }

  .submitted-data {
    border: transparent !important;

    .submitted-content {
      .field,
      .field-name {
        padding: 10px;
      }

      .field-name {
        width: 250px;
      }
    }
  }

  .data-table table tbody tr {
    cursor: default;
  }
}

.submitted-data {
  .button-container {
    font-size: 14px;

    .button {
      display: inline-block;
      padding: 7px 20px;
      margin: 10px 10px 10px 0;
      text-decoration: none;
    }
  }
  .submission-buttons {
    text-align: right;
    padding-top: 15px;

    .navigation-button {
      margin: 0px 5px;
    }
  }
}

.submission-meta-data {
  border: 1px solid #dedbd7;
  padding: 15px;
  margin-top: 10px;
}

.modal-body .submitted-data {
  border: transparent !important;

  .data-table table tbody tr {
    cursor: default;
  }
}

.data-table-settings
.modal-body {
  display: grid;
  grid-template-columns: repeat(auto-fit, 25%);

  .campaign-form {
    margin: 0 10px;
    line-height: 1.2em;
  }
}

.related-component
.modal-body {
  display: grid;

  .campaign-form {
    margin: 0 10px;
    line-height: 1.2em;
  }
}

.infoMessage {
  color: #f34017;
  font-size: 13px;
  margin-top: 5px;
}

#panelImg-full-width {
  height: 164px;
  width: 205px;
  object-fit: cover;
}

#panelImg-medium {
  height: 164px;
  width: 222px;
  object-fit: cover;
}

#panelImg-large {
  height: 160px;
  width: 186px;
  object-fit: cover;
}

#panelImg {
  height: 160px;
  width: 174px;
  object-fit: cover;
}

#panelCheck {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(148, 146, 146, 0.2), 0 6px 20px 0 rgba(102, 100, 100, 0.19);
}

#inputText {
  position: absolute;
  margin-top: -158px;
  margin-left: 0.2rem;
  width: 3rem;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #dfe1de;
}

.panelList-o {
  font-size: 16px;
  height: 5px;
  overflow-y: scroll;
}

.panelList-s {
  font-size: 16px;
  height: 33px;
  overflow-y: scroll;
}

.panelList-m {
  font-size: 16px;
  height: 55px;
  overflow-y: scroll;
}

.panelList-l-small {
  font-size: 16px;
  height: 94px;
  overflow-y: scroll;
}

.panelList-l {
  font-size: 16px;
  height: 100px;
  overflow-y: scroll;
}

.iFrameModal {
  border: none;
  height: 37.916em;
  width: 100%;
}

#modalButton {
  color: #ffffff;
  background-color: #ff8513;
  border: none;
  border-radius: 4px;
  padding-right: 22px;
  padding-left: 22px;
  height: 38px;
  margin-right: 1.1rem;
}

#sequenceButton {
  text-align: left;
  background-color: #dcdce0dd;
  color: #005fcc;
  margin-top: 0.3rem;
  margin-right: 0.3rem;
  margin-left: 1.1rem;
}

#sequenceButton:hover {
  background-color: #d3d3d7dd;
}

#submissionDeleteButton {
  color: #fff;
  background-color: #d02e1d;
  border-radius: 4px;
  padding-right: 22px;
  padding-left: 22px;
  height: 38px;
  margin-right: 10px;
}

#submissionEditButton {
  color: #fff;
  background-color: #04ca9a;
  border-radius: 4px;
  padding-right: 22px;
  padding-left: 22px;
  height: 38px;
  margin-right: 10px;
}

.checkAllPicker {
  text-align: left;
  font-size: 15px;
  width: 100%;
  margin-left: -5px;
}

.checkTreePicker {
  position: absolute;
  z-index: 2000;
}

.rs-picker-toggle-wrapper {
  width: 100%;
  border-radius: 0;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #555555;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #565656;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border-color: #ccc;
}

.modal-dialog.relatedComponent-dialog,
.modal-dialog.imageSelector-dialog {
  width: 800px;

  .modal-content.delete-dialog-content {

    .modal-body {
      text-align: center;
    }

    .modal-footer {
      border-top: 0px;

      .navigation-button {
        margin-inline: 5px;
      }
    }
  }
}

@media screen and (max-width:580px) {
  .modal-dialog.relatedComponent-dialog,
  .modal-dialog.imageSelector-dialog {
      width: 100%;
  }
}

.modal-dialog.delete-dialog{
  width: 400px;

  .modal-content.delete-dialog-content {

    .modal-body {
      text-align: center;
    }

    .modal-footer {
      border-top: 0px;

      .navigation-button {
        margin-inline: 5px;
      }
    }
  }
}

.modalButton:hover {
  background-color: #df6c00;
}

.submission-data {

  .modal-dialog {
    width: 50%;
    max-width: 80vw;

    &.delete-dialog {
      max-width: 420px;

      .help-block {
        text-align: center;
      }
    }
    &.export-dialog {
      max-width: 500px;
    }
    &.import-dialog {
      max-width: 500px;
    }
    .modal-footer {
      border-top: none;

      .center {
        text-align: center;
      }

      .navigation-button {
        margin: 0px 5px;

        &.primary,
        &.danger {
          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
    .dialog-row {
      padding-bottom: 20px;
    }
    .help-block {
      font-size: 14px;
    }
    .import-help {
      display: flex;

      span:first-of-type {
        flex: 1;
      }
    }
    input {
      background-color: #f2f2f2;
    }

    .custom-import-file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .custom-import-file + label {
      cursor: pointer;
      color: #4d6cef;
      border: 1px solid #4d6cef;
      border-radius: 4px;
      width: 80px;
      margin-left: 12px;
      margin-top: 11px;
      font-size: 12px;
      padding-top: 4px;
      padding-left: 12px;
      height: 55%;
    }

    .import-file-upload-wrapper {
      width: 100%;
      height: 50px;
      background-color: #f2f2f2;
      display: flex;

      label:last-of-type {
        flex: 1;
        text-align: right;
        font-size: 14px;
        font-weight: 100;
        padding: 15px 13px 0px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .modal-select-all {
    padding-left: 25px;
    padding-top: 15px;
    margin-bottom: -20px;
  }
  .info-message {
    color: #494e57;
    font-size: 16px;
    margin: 0 5px;
  }
}

.btn-reset {
  font-size: 14px;
  font-weight: 600;
  color: #ed2714;
  &:hover {
    color: #ed2714;
  }
}

// ********************************************* //
// ************* Test Endpoint Modal *********** //
// ********************************************* //
.data-source-dialog {
  width: 80%;
  max-width: 800px;

  div.jsoneditor {
    height: 600px !important;
  }

  .modal-footer {
    border-top: none;

    .center {
      text-align: center;
    }

    .navigation-button {
      margin: 0px 5px;
    }
  }
}

// ********************************************* //
// ************ Table Settings Modal *********** //
// ********************************************* //
.description-layout {
  background-color: #fff;
  .description-table-modal {
    padding: 0;
    .builder.form-group label {
      color: #000;
    }
  }
}

// ********************************************* //
// **************** DataTable ****************** //
// ********************************************* //
.data-table.full-border {
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  border-right: 1px solid #dfdddb;
  border-left: 1px solid #dfdddb;

  .data-table {
    &-options {
      border-top: 1px solid #dfdddb;
      border-right: none;
      border-left: none;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      overflow-x: hidden;
      background-color: #fff;
    }
    &-actions {
      margin: 10px 10px 10px 20px;
    }
  }

  table {
    thead tr.header {
      background-color: #ededed;
      white-space: nowrap;
    }

    td, th {
      border: 1px solid #dfdddb;
    }

    tr {
      &:hover:not(.header),
      &.selected,
      &.selected:nth-child(odd) {
        background-color: @brand-light-gray1;
        color: inherit;
      }
    }

    tr {
      background-color: #fff;
    }

    .checkbox {
      display: table-cell;
    }

  }

  .user-details-table {
    tr {
      background-color: #fff;

      &:hover:not(.header),
      &.selected,
      &.selected:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
  // ********************************************* //
  // ************** CUSTOM CHECKBOX ************** //
  // ********************************************* //
  .cc {
    &-container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      user-select: none;
      padding: 0;
      margin: auto;
      height: 22px;
      width: 22px;
      &:hover .cc-checkbox ~ .cc-checkmark {
        background-color: #ccc;
      }
      .cc-checkbox:checked ~ .cc-checkmark {
        background-color: #2196F3;
      }
      .cc-checkbox:checked ~ .cc-checkmark:after {
        display: block;
      }
      .cc-checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
    &-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 3px;
      top: 5px;
      z-index: 1;
    }
    &-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;
      border: 1px solid #eee;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
}

// ********************************************* //
// *************** FilterSearch **************** //
// ********************************************* //
.filter-options {
  .filter-reset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .quantity {
      font-size: 19px;
      color: #3c51eb;
      font-weight: 600;
    }
  }
  .filter-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    .filter-select {
      display: inherit;
    }
    .search-bar {
      border-radius: 4px;
      background-color: white;
      border: 1px solid #605c58;
    }
    .builder.form-group {
      margin-right: 15px;
      margin-bottom: 0;
    }
    .builder.form-group select {
      height: 40px;
      font-size: 14px;
      appearance: none;
      font-weight: 600;
      border-radius: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid #605c58;
      padding: 4px 17px 4px 8px;
      overflow: hidden;
      background: #f2f2f2 url('@{icons-path}/select-arrow.png') no-repeat center right 8px;
      &:hover {
        box-shadow: 0 0 2px 0 #9c9c9c
      }
      &:focus {
        background-image: url('@{icons-path}/select-arrow.png');
      }

    }
  }
}

// *************************************************** //
// *************** Submission Filters **************** //
// *************************************************** //

.filters-search-bar-submissions-wrap {
  padding: 15px 0px 15px 0px;
  text-align: right;

  .search-bar {
    background-color: #f2f2f2;
    float: left;
    text-align: left;
    width: 40%;

    input {
      width: 90%;
    }
  }

  .dark {
    color: #494e57;
  }

  button {
    padding-right: 30px;

    span {
      padding-right: 5px;
    }
  }
}

.filters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 23%);
  grid-row-gap: 10px;
  grid-column-gap: 2%;
  width: 102%;
  margin: 10px auto;
  list-style: none;

  input {
    border-radius: 4px!important;
    border-color: @filter-border-color !important;
    height: @filter-max-height;
  }

  .search-bar {
    max-height: @filter-max-height;
    line-height: @filter-max-height;
    border-color: @filter-border-color;
    background-color: white;
  }

  .builder.form-group {
    margin-bottom: 0!important;

    label {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  // Date time range filter
  .filter-datetime-range {
    display: flex;

    .rdt {
      width: 100%;
    }

    .clear {
      width: 50% !important;
      min-width: 71px !important;
      max-width: 72px !important;
    }

    .rdt-icon {
      display: flex;
      text-align: center;
      width: 20%;
      min-width: 36px;
      max-width: 36px;

      .css-1okebmr-indicatorSeparator {
        margin-top: 7px;
        margin-bottom: 7px;
        background-color: hsl(0,0%,80%);
        width: 1px;
      }
    }

    > div:first-child {
      margin-right: 0.5%;
    }

    > div:nth-child(2) {
      margin-left: 0.5%;
    }

    .datetime-field {
      width: 50%;
      display: flex;
      border-radius: 4px!important;
      border: 1px solid @filter-border-color !important;

      input {
        cursor: default !important;
        border: none !important;
        box-shadow: none !important;
        background-color: white;
        height: 32px;
      }

      .glyphicon {
        min-width: 35px;
        max-width: 36px;
        color: @filter-border-color;
        padding: 9px;
        top: 0;
      }

      .glyphicon-remove:hover {
        font-weight: bold;
        color: #808080;
      }
    }
  }

  // Dropdown filter
  .filter-select {
    max-height: @filter-max-height;

    > div:first-child {
      max-height: @filter-max-height;

      div {
        max-height: @filter-max-height;
      }
    }

    .css-yk16xz-control {
      min-height: @filter-max-height;
    }

    .css-1pahdxg-control {
      max-height: @filter-max-height;
      min-height: @filter-max-height;

      div {
        max-height: @filter-max-height;
      }
    }

    .css-1hwfws3 {
      position: initial;
    }
  }
}
