@wotif-color: #79c230;

.wotif {
  .submit {
    background-color: @wotif-color;
    &:hover {
      background-color: darken(@wotif-color, 20%);
    }
  }

  .repeat-module {
    background-color: @wotif-color;
    &:hover {
      background-color: darken(@wotif-color, 20%);
    }
  }

  .campaign-form h2 {
    color: @wotif-color;
  }

  .form-link {
    color: @wotif-color;

    &:hover {
      color: darken(@wotif-color, 20%);
    }
  }

  .progress {
    .progress-bar {
      background-color: @wotif-color;
    }
  }
}
