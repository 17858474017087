.avatar {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid @brand-primary;

  img {
    width: 100%;
    height: 100%;
  }

  .edit {
    position: absolute;
    bottom: -55px;
    left: 0;
    right: 0;
    opacity: 0;
    font-family: @font-family-sans;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 15px;
    margin-bottom: 0;
    color: @brand-white;
    background: #484e56;
    transition: @transition-default;
    cursor: pointer;
  }

  &.editable {
    width: 240px;
    height: 240px;
    margin: 40px auto;
    z-index: 1;

    &:hover {

      .edit {
        opacity: 1;
        bottom: 0;
      }
    }
  }
}
