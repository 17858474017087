.submissions.users {
  .filters-grid {
    margin-bottom: 25px
  }

  .users-header {
    display: flex;
    justify-content: space-between;

    .navigation-button {
      margin-top: 20px;
    }
  }

  & + div > .modal {
    label {
      display: block;
    }

    input {
      margin-bottom: 10px;
      padding: 3px 8px;
      width: 100%;
    }
  }
}

.user-details {
  .role-name {
    color: @brand-dark-gray2;
    font-size: 14px;
    padding: 6px 8px;
    background-color: #dedede;
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    .remove-role {
      border-radius: 100%;
      height: 18px;
      width: 18px;
      line-height: 16px;
      text-align: center;
      padding: 0;
      margin-left: 5px;

      &:hover {
        background-color: #c75028;
        color: #fff;
      }
    }
  }

  .user-details-table th > .header-button {
    display: flex;
    margin: 0;

    .icon-button {
      margin-left: auto;
    }
  }
}
