.layout-field {
  column-count: 3;
  margin-bottom: 10px;

  input[type=checkbox] {
    display: none;
  }
  .layout-icon {
    filter: grayscale(100%);

    &.active {
      filter: grayscale(0);
    }
    &.default {
      box-shadow: 0 0px 4px 0.1px #4b4b4b;
    }
  }
}

.campaign-form {
  .custom-radio-label {
    display: block;
  }
}
.custom-radio-label {
  input[type=radio] {
    display: none;

    + .radio-label > img {
      filter: grayscale(100%);
    }
    &:checked {
      + .radio-label > img {
        filter: grayscale(0);
      }
    }
  }
}

