.paginator {
  margin-left: 30px;

  > span {
    line-height: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }

  &-theader,
  &-tfooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
  }

  &-tfooter {
    margin-top: 25px;

    .items-per-page-container {
      .selector {
        border: 1px solid #dedede;
        appearance: none;
        border-radius: 3px;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
        height: 30px;
        overflow: hidden;
        background: #fff url('@{icons-path}/select-arrow.png') no-repeat center right 8px;
        cursor: pointer;
      }
    }
  }

  .navigation-button {
    color: #000;
    height: 30px;
    font-size: 10px;
    padding: 3px 12px 0 12px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dedede;
    vertical-align: middle;
  }
}
