.float-right {
  float: right;
}

.body-layout {
  background-color: @brand-light-gray1;

  .alert {
    margin: 100px auto 0;
  }
}

span.clickable {
  color: @brand-blue;
  text-transform: uppercase;
  font-size: 15px;
  top: 3px;
  left: 5px;
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.mask.displayed {
  opacity: 1;
}

.centering-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.movable-view {
  padding: 10px;
  margin-top: 7px;
  border: 2px dashed @brand-blue;
  margin-bottom: 7px;
  border-radius: 2px;
  position: relative;

  &.static {
    border-style: solid;
  }

  &.over {
    background-color: #dcdcdc;
  }

  b {
    font-size: 16px;
    min-width: 30px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;
    vertical-align: middle;
  }

  .movable-name {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }

  .movable-action {
    position: absolute;
    right: 30px;
    display: none;
    top: ~'calc(50% - 15px)';
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .module-content {
    position: relative;
  }

  .revision-subtitle {
    left: 10px;
    top: 27px;
  }

  span {
    &.remove,
    &.unlink {
      position: absolute;
      display: none;
      right: 6px;
      color: #d01c34;
      cursor: pointer;
    }

    &.remove {
      top: ~'calc(50% - 8px)';
    }

    &.unlink {
      top: 14px;
      right: 16px;
    }

    &.hint {
      font-size: 0.8em;

      b {
        font-size: inherit;
        min-width: inherit;
        margin: 0;
      }
    }
  }

  &:hover {
    .movable-action {
      display: block;
    }

    span {
      &.remove,
      &.unlink {
        display: block;
      }
    }
  }
}

.builder.form-group {
  margin-bottom: 20px;

  .inline {
    display: inline-block;
  }

  label {
    display: block;
    color: @brand-dark-gray2;
    font-family: @font-family-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .one-line label {
    display: inline-block;
  }

  select {
    border: 1px solid @color-border;
    border-radius: 2px;
    display: block;
    width: 100%;
    font-size: 14px;
    padding: 8px;
    background-color: #fff;
    height: 34px;

    &.inline {
      width: auto;
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  &.inline select {
    height: 38px;
  }

  input[type=text], input[type=number], input[type=date], textarea {
    border: 1px solid @color-border;
    border-radius: 2px;
    display: block;
    width: 100%;
    font-size: 14px;
    padding: 8px;
  }

  input[type=text].error, input[type=number].error, textarea.error {
    border: 2px solid @color-error;
  }

  input[type=radio] {
    margin: 0 0 0 -20px;
  }

  input[type="checkbox"],
  .checkbox {
    margin-left: 5px;
  }

  span.error-message {
    display: inline-block;
    margin-top: 4px;
    color: @color-error;
  }

  .markdown-label {
    font-size: 0.8em;
    margin-left: 5px;
  }
}

select.moderationStatus {
  padding: 3px;

  option.initial {
    color: #4d6cef;
  }

  option.transit {
    color: #ffd859;
  }

  option.clean {
    color: #04ca9a;
  }

  option.dirty {
    color: #d02e1d;
  }
}

.elem {
  padding: 24px;
  margin-top: 19px;
  margin-bottom: 17px;
  border-radius: 4px;
}

.first .elem {
  background-color: #d5d5d5;
}

.second .elem {
  background-color: #fff;
}

.third .elem {
  background-color: #f5f5f5;
}

.campaign-editor {
  width: 50%;
}

.navigation {
  float: right;
  margin-top: -28px;

  .spinning {
    margin-right: 20px;
  }

  .error-message {
    margin-right: 10px;
    color: #d01c34;
  }
}

.actions {
  > span {
    padding-left: 10px;
    padding-right: 10px;
  }

  > span.delete {
    color: @brand-blue;
    cursor: pointer;
    padding: 0;
  }
}

.notification {
  position: absolute;
  z-index: 99999;
  right: 20px;
  padding: 15px;
  top: 14px;
  display: flex;
  width: 100%;
  max-width: 400px;
  opacity: 1;
  transition: opacity 0.5s;
  background-color: white;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .15);

  span {
    display: inline-block;
    vertical-align: middle;
  }

  span.text {
    width: ~'calc(100% - 20px)';
  }

  span.glyphicon {
    color: #d01c34;
    cursor: pointer;
  }

  button {
    background: none;
    border: 0;
  }
}

.inline-block {
  display: inline-block;
}

.notification.transition-out {
  opacity: 0;
}
