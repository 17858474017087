.overview-menu {
  background-color: @brand-light-gray1;
  border-radius: 3px;
  margin-top: 30px;

  + .full-width-panel {
    border: none;
    border-radius: 0 4px 4px 4px;
    background-color: #f0f0f0;
    padding: 0;

    > .standard {
      border: 1px solid #eee;
      background-color: #fff;
      padding: 20px;
    }
  }
}

.menu-item {
  display: inline-block;
  color: #fff;
  height: 40px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  border: none;
  border-right: 2px solid @brand-light-gray1;
  .button-rollover(#979797);

  &:first-child {
    border-radius: 4px 0 0;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
  }

  &:only-child {
    border-radius: 4px 4px 0 0;
  }

  &.selected {
    .button-rollover(@brand-blue);
  }
}
