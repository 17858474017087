.data-table {
  position: relative;
  font-size: 16px;

  .action-column {
    width: 190px;
    text-align: center;
  }

  .table-actions {
    text-align: center;
    white-space: nowrap;
    .glyphicon-option-horizontal {
      color: #979797;
      font-size: 20px;
      padding: 0px 35px 0px 35px;
    }
  }

  .action-button {
    font-size: 1.2em;
    margin: -16px 6px 0;
    top: 50%;
    vertical-align: bottom;

    &.active {
      position: absolute;
      right: 0;
      top: 50%;
      padding: 8px 15px;
      border-radius: 2px;
      margin-top: -18px;
      font-family: @font-family-sans;
      white-space: nowrap;
      text-transform: uppercase;
      transition: @transition-default;

      &:before {
        display: none;
      }
    }

    &.glyphicon-trash {
      color: #0b0a0a;

      &.active {
        background-color: #f56565;
        color: #fff;

        &:hover {
          background-color: darken(#f56565, 20%);
        }
      }
    }
  }

  button.navigation-button.action-button {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 2px;
    margin-right: 2px;
    height: 30px;
  }

  .error-request {
    font-size: 17px;
  }

  .loading {
    font-size: 17px;
    text-align: center;

    .glyphicon {
      color: @brand-blue;
      font-size: 50px;
      display: block;
      margin-bottom: 20px;
    }
  }
  table {
    position: relative;
    width: 100%;

    thead {
      color: #393d45;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;

      tr.header {
        background-color: #fff;
      }

      th.sortable {
        cursor: pointer;
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }

      tr.selected {
        background-color: @brand-blue;
        color: #fff;

        td.name,
        .revision-subtitle,
        .glyphicon-option-horizontal {
          color: #fff;
        }
      }
      tr.highlighted {
        background-color: #dee2f1 !important;
        outline: 1px solid @brand-blue;
        outline-offset: -1px;
      }
    }

    tr {
      height: 50px;
    }

    tr:nth-child(odd) {
      background-color: #fbfafa;
    }

    td, th {
      position: relative;
      padding: 10px;
      line-height: 1.2em;
      background-clip: padding-box; // To fix FF bug with background over border
    }
    td {
      font-weight: 400;

      &.name {
        color: #494e57;
      }
    }
  }

  &-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  &-actions {
      list-style: none;
      display: block;
      padding: 0;
      li {
          display: inline-block;
          margin-right: 25px;
      }
      .text-selected {
          font-size: 14px;
          font-weight: 600;
      }
  }
  &-settings {

  }

  .btn {
    &-reset,
    &-delete,
    &-export,
    &-settings {
        font-size: 14px;
        font-weight: 600;
    }
    &-reset,
    &-delete {
        color: #ed2714;
        &:hover {
            color: #ed2714;
        }
    }
    &-delete,
    &-export {
        text-transform: uppercase;
    }
  }

  &-input,
  input[type="radio"],
  input[type="checkbox"] {
    margin: 0 5px 0 0;
  }
}

.revision-subtitle,
.deleted-subtitle {
  display: block;
  font-size: 10px;
}

.revision-subtitle {
  color: #666;
}

.deleted-subtitle {
  color: #f56565;
}

.loading-message,
.empty-result {
  padding-top: 15px;
}

.sub-description{
  font-size: 12px;
  margin: 0;
  padding: 0;
}

