div.jsoneditor {
  height: 300px !important;
}

div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor-readonly,
div.jsoneditor-default {
  border: 1px solid transparent;
  min-height: 16px;
  min-width: 32px;
  padding: 2px;
  margin: 1px;
  word-wrap: break-word;
  float: left;
}

/* adjust margin of p elements inside editable divs, needed for Opera, IE */
div.jsoneditor-field p,
div.jsoneditor-value p {
  margin: 0;
}

div.jsoneditor-value {
  word-break: break-word;
}

div.jsoneditor-readonly {
  min-width: 16px;
  color: #808080;
}

div.jsoneditor-empty {
  border-color: #d3d3d3;
  border-style: dashed;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-empty::after,
div.jsoneditor-value.jsoneditor-empty::after {
  pointer-events: none;
  color: #d3d3d3;
  font-size: 8pt;
}

div.jsoneditor-field.jsoneditor-empty::after {
  content: "field";
}

div.jsoneditor-value.jsoneditor-empty::after {
  content: "value";
}

div.jsoneditor-value.jsoneditor-url,
a.jsoneditor-value.jsoneditor-url {
  color: green;
  text-decoration: underline;
}

a.jsoneditor-value.jsoneditor-url {
  display: inline-block;
  padding: 2px;
  margin: 2px;
}

a.jsoneditor-value.jsoneditor-url:hover,
a.jsoneditor-value.jsoneditor-url:focus {
  color: #ee422e;
}

div.jsoneditor td.jsoneditor-separator {
  padding: 3px 0;
  vertical-align: top;
  color: #808080;
}

div.jsoneditor-field[contenteditable=true]:focus,
div.jsoneditor-field[contenteditable=true]:hover,
div.jsoneditor-value[contenteditable=true]:focus,
div.jsoneditor-value[contenteditable=true]:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
  background-color: #FFFFAB;
  border: 1px solid yellow;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-highlight-active,
div.jsoneditor-field.jsoneditor-highlight-active:focus,
div.jsoneditor-field.jsoneditor-highlight-active:hover,
div.jsoneditor-value.jsoneditor-highlight-active,
div.jsoneditor-value.jsoneditor-highlight-active:focus,
div.jsoneditor-value.jsoneditor-highlight-active:hover {
  background-color: #ffee00;
  border: 1px solid #ffc700;
  border-radius: 2px;
}

div.jsoneditor-value.jsoneditor-string {
  color: #006000;
}

div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
  min-width: 16px;
}

div.jsoneditor-value.jsoneditor-number {
  color: #ee422e;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00;
}

div.jsoneditor-value.jsoneditor-null {
  color: #004ED0;
}

div.jsoneditor-value.jsoneditor-invalid {
  color: #000000;
}

div.jsoneditor-default {
  color: #808080;
  padding-left: 10px;
}

div.jsoneditor-tree button.jsoneditor-button {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: transparent url('@{icons-path}/jsoneditor-icons.svg');
}

div.jsoneditor-mode-view tr.jsoneditor-expandable td.jsoneditor-tree,
div.jsoneditor-mode-form tr.jsoneditor-expandable td.jsoneditor-tree {
  cursor: pointer;
}

div.jsoneditor-tree button.jsoneditor-collapsed {
  background-position: 0 -48px;
}

div.jsoneditor-tree button.jsoneditor-expanded {
  background-position: 0 -72px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu {
  background-position: -48px -72px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu:hover,
div.jsoneditor-tree button.jsoneditor-contextmenu:focus,
div.jsoneditor-tree button.jsoneditor-contextmenu.jsoneditor-selected,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu {
  background-position: -48px -48px;
}

div.jsoneditor-tree *:focus {
  outline: none;
}

div.jsoneditor-tree button.jsoneditor-button:focus {
  /* TODO: nice outline for buttons with focus
  outline: #97B0F8 solid 2px;
  box-shadow: 0 0 8px #97B0F8;
  */
  background-color: #f5f5f5;
  outline: #e5e5e5 solid 1px;
}

div.jsoneditor-tree button.jsoneditor-invisible {
  visibility: hidden;
  background: none;
}

div.jsoneditor-tree div.jsoneditor-show-more {
  display: inline-block;
  padding: 3px 4px;
  margin: 2px 0;

  background-color: #e5e5e5;
  border-radius: 3px;
  color: #808080;

  font-family: arial, sans-serif;
  font-size: 10pt;
}

div.jsoneditor-tree div.jsoneditor-show-more a {
  display: inline-block;
  color: #808080;
}

div.jsoneditor-tree div.jsoneditor-show-more a:hover,
div.jsoneditor-tree div.jsoneditor-show-more a:focus {
  color: #ee422e;
}

div.jsoneditor-tree div.jsoneditor-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 4px;

  border: 1px solid #808080;
  cursor: pointer;
}

div.jsoneditor div.jsoneditor-anchor .picker_wrapper.popup.popup_bottom {
  top: 28px;
  left: -10px;
}

div.jsoneditor-tree div.jsoneditor-date {
  background: #a1a1a1;
  color: white;
  font-family: arial, sans-serif;
  border-radius: 3px;
  display: inline-block;
  padding: 3px;
  margin: 0 3px;
}

div.jsoneditor {
  color: #1A1A1A;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  line-height: 100%;
}


div.jsoneditor-tree table.jsoneditor-tree {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

div.jsoneditor-outer {
  position: static;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.jsoneditor-outer.has-nav-bar {
  margin-top: -26px;
  padding-top: 26px;
}

div.jsoneditor-outer.has-status-bar {
  margin-bottom: -26px;
  padding-bottom: 26px;
}

div.jsoneditor-outer.has-main-menu-bar {
  margin-top: -35px;
  padding-top: 35px;
}

div.jsoneditor-outer.has-nav-bar.has-main-menu-bar {
  margin-top: -61px;
  padding-top: 61px;
}

textarea.jsoneditor-text,
.ace-jsoneditor {
  min-height: 150px;
}

div.jsoneditor-tree {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

#json-table table.jsoneditor-tree {
  tbody {
    tr {
      height: 0px;

      &:hover {
        background-color: transparent;
      }

      td {
        border: none;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

textarea.jsoneditor-text {
  width: 100%;
  height: 100%;
  margin: 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  outline-width: 0;
  border: none;
  background-color: white;
  resize: none;
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: #d3d3d3;
}

tr.jsoneditor-selected button.jsoneditor-dragarea,
tr.jsoneditor-selected button.jsoneditor-contextmenu {
  visibility: hidden;
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu {
  visibility: visible;
}

div.jsoneditor-tree button.jsoneditor-dragarea {
  background: url('@{icons-path}/jsoneditor-icons.svg') -72px -72px;
  cursor: move;
}

div.jsoneditor-tree button.jsoneditor-dragarea:hover,
div.jsoneditor-tree button.jsoneditor-dragarea:focus,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
  background-position: -72px -48px;
}

div.jsoneditor tr,
div.jsoneditor th,
div.jsoneditor td {
  padding: 0;
  margin: 0;
}

div.jsoneditor td {
  vertical-align: top;
}

div.jsoneditor td.jsoneditor-tree {
  vertical-align: top;
}

div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor td,
div.jsoneditor th,
div.jsoneditor textarea,
.jsoneditor-schema-error {
  font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
  font-size: 10pt;
  color: #1A1A1A;
}





/* popover */
.jsoneditor-schema-error {
  cursor: default;
  display: inline-block;
  /*font-family: arial, sans-serif;*/
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 24px;
}

div.jsoneditor-tree .jsoneditor-button.jsoneditor-schema-error {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url('@{icons-path}/jsoneditor-icons.svg')  -168px -48px;
}

.jsoneditor-text-errors tr.jump-to-line:hover {
  text-decoration: underline;
  cursor: pointer;
}

.jsoneditor-schema-error .jsoneditor-popover {
  background-color: #4c4c4c;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  color: #fff;
  display: none;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  z-index: 4;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-above {
  bottom: 32px;
  left: -98px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-below {
  top: 32px;
  left: -98px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-left {
  top: -7px;
  right: 32px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-right {
  top: -7px;
  left: 32px;
}

.jsoneditor-schema-error .jsoneditor-popover:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  content: '';
  display: block;
  left: 50%;
  margin-left: -7px;
  position: absolute;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-above:before {
  border-top: 7px solid #4c4c4c;
  bottom: -7px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-below:before {
  border-bottom: 7px solid #4c4c4c;
  top: -7px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-left:before {
  border-left: 7px solid #4c4c4c;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: '';
  top: 19px;
  right: -14px;
  left: inherit;
  margin-left: inherit;
  margin-top: -7px;
  position: absolute;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-right:before {
  border-right: 7px solid #4c4c4c;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: '';
  top: 19px;
  left: -14px;
  margin-left: inherit;
  margin-top: -7px;
  position: absolute;
}

.jsoneditor-schema-error:hover .jsoneditor-popover,
.jsoneditor-schema-error:focus .jsoneditor-popover {
  display: block;
  -webkit-animation: fade-in .3s linear 1, move-up .3s linear 1;
  -moz-animation: fade-in .3s linear 1, move-up .3s linear 1;
  -ms-animation: fade-in .3s linear 1, move-up .3s linear 1;
}

@-webkit-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-moz-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-ms-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
/*@-webkit-keyframes move-up {*/
  /*from   { bottom: 24px; }*/
  /*to { bottom: 32px; }*/
/*}*/
/*@-moz-keyframes move-up {*/
  /*from   { bottom: 24px; }*/
  /*to { bottom: 32px; }*/
/*}*/
/*@-ms-keyframes move-up {*/
  /*from   { bottom: 24px; }*/
  /*to { bottom: 32px; }*/
/*}*/


/* JSON schema errors displayed at the bottom of the editor in mode text and code */

.jsoneditor .jsoneditor-validation-errors-container {
  max-height: 130px;
  overflow-y: auto;
}

.jsoneditor .jsoneditor-additional-errors {
  position: absolute;
  margin: auto;
  bottom: 31px;
  left: calc(50% - 92px);
  color: #808080;
  background-color: #ebebeb;
  padding: 7px 15px;
  border-radius: 8px;
}

.jsoneditor .jsoneditor-additional-errors.visible{
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.jsoneditor .jsoneditor-additional-errors.hidden{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.jsoneditor .jsoneditor-text-errors {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #ffd700;
}

.jsoneditor .jsoneditor-text-errors td {
  padding: 3px 6px;
  vertical-align: middle;
}

.jsoneditor .jsoneditor-text-errors tr {
  background-color: #ffef8b;
}

.jsoneditor .jsoneditor-text-errors tr.parse-error {
  background-color: #ee2e2e70;
}

.jsoneditor-text-errors .jsoneditor-schema-error {
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  cursor: pointer;
}

.jsoneditor-text-errors tr .jsoneditor-schema-error {
  background: url('@{icons-path}/jsoneditor-icons.svg')  -168px -48px;
}

.jsoneditor-text-errors tr.parse-error .jsoneditor-schema-error {
  background: url('@{icons-path}/jsoneditor-icons.svg')   -25px 0px;
}

.fadein {
  -webkit-animation: fadein .3s;
  animation: fadein .3s;
  -moz-animation: fadein .3s;
  -o-animation: fadein .3s;
}

@-webkit-keyframes fadein {
  0% {opacity: 0}
  100% {opacity: 1}
}

@-moz-keyframes fadein{
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes fadein {
  0% {opacity: 0}
  100% {opacity: 1}
}

@-o-keyframes fadein {
  0% {opacity: 0}
  100% {opacity: 1}
}
