table.jsoneditor-search input,
table.jsoneditor-search div.jsoneditor-results {
  font-family: arial, sans-serif;
  font-size: 10pt;
  color: #1A1A1A;
  background: transparent; /* For Firefox */
}

table.jsoneditor-search div.jsoneditor-results {
  color: white;
  padding-right: 5px;
  line-height: 24px;
  padding-top: 2px;
}

table.jsoneditor-search {
  position: absolute;
  right: 4px;
  top: 4px;
  border-collapse: collapse;
  border-spacing: 0;
}

table.jsoneditor-search div.jsoneditor-frame {
  border: 1px solid transparent;
  background-color: white;
  padding: 0 2px;
  margin: 0;
}

table.jsoneditor-search div.jsoneditor-frame table {
  border-collapse: collapse;
}

table.jsoneditor-search input {
  width: 120px;
  border: none;
  outline: none;
  margin: 1px;
  line-height: 20px;
}

table.jsoneditor-search button {
  width: 16px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  background: url('@{icons-path}/jsoneditor-icons.svg');
  vertical-align: top;
}

table.jsoneditor-search button:hover {
  background-color: transparent;
}

table.jsoneditor-search button.jsoneditor-refresh {
  width: 18px;
  background-position: -99px -73px;
}

table.jsoneditor-search button.jsoneditor-next {
  cursor: pointer;
  background-position: -124px -73px;
}
table.jsoneditor-search button.jsoneditor-next:hover {
  background-position: -124px -49px;
}

table.jsoneditor-search button.jsoneditor-previous {
  cursor: pointer;
  background-position: -148px -73px;
  margin-right: 2px;
}
table.jsoneditor-search button.jsoneditor-previous:hover {
  background-position: -148px -49px;
}
