.button-rollover(@color) {
  background-color: @color;
  &:focus, &:hover, &:active {
    outline: none;
  }
  &:hover {
    background-color: darken(@color, 10);
  }
  &:active {
    background-color: darken(@color, 20);
  }
}

/**
 * Mixin that mimics the 'background-size: cover' property
 * for a given img element with a relative positioned parent.
 * @todo Check with the client what we want for header image style, might be a better solution if we have more specifications
 */
.img-cover() {
  width: auto           !important;
  max-width: none       !important;
  position: absolute;
  margin: auto;
  height: auto;
  left: -10000%;
  right: -10000%;
  top: -10000%;
  bottom: -10000%;
  min-width: 1000%;
  min-height: 1000%;
  transform: scale(.1);
}
