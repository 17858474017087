// Base form settings

input[readonly]:focus,
textarea[readonly]:focus {
  outline: none;
}

::placeholder {
  color:@brand-light-gray2;
}

.form-font {
  .base-font;
}

.field-theme {
  .base-font;
  border: 1px solid @form-field-border-color;
  border-radius: @form-field-border-radius;
}

.form-container {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.31);
  margin: floor((@grid-gutter-width / 2));
  padding: floor((@grid-gutter-width / 2));

  .view {
    &:not(:last-child) {
      margin-bottom: floor((@grid-gutter-width / 2));
    }
  }
}

.form-header {
  text-align: center;
  margin-bottom: 50px;
}

.form-title {
  .h1;
}

.form-subtitle {
  .h3;
}

.form-link {
  .base-font;
  color: @brand-primary;
  transition: @transition-default;

  &:hover {
    color: darken(@brand-primary, 20%);
  }
}

.form-group {
  label,
  .form-group-label {
    ol,
    ul {
      margin: 0;
      padding-left: 0;
      list-style-position: inside;
    }
  }
}

.relatedComponentLoading {
  text-align: center;
}

.chartLoading {
  padding-top: 80px;
  text-align: center;
  margin-right: 135px;
}

.filterBy {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.filterLabel {
  flex: 0 0 65px;
  padding-bottom: 13px;
}

.filterSelect {
  .builder.form-group select {
    border-color: #dbdbdbd4;
    height: 36px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 11%);
  }

  flex: 0 0 130px;
  padding-right: 8px;
}

.validationMessage {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: #b20633;
  padding-right: 17px;
}

.dailyFilterLabel {
  flex: 0 0 65px;
  padding-bottom: 13px;
  text-align: right;
  padding-right: 5px;
}

.dailyFilterSelect {
  .builder.form-group select {
    border-color: #dbdbdbd4;
    height: 36px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 11%);
  }

  *.rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-width: 230px;
    max-width: 230px;
    min-height: 0px !important;
    margin-bottom: 12px !important;
    border: none !important;
  }

  flex: 0 0 130px;
}

.dailyAirlineFilterSelect {
  .builder.form-group select {
    border-color: #dbdbdbd4;
    height: 36px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 11%);
  }

  *.rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-width: 230px;
    max-width: 230px;
    min-height: 0px !important;
    margin-bottom: 12px !important;
    border: none !important;
  }

  flex: 0 0 130px;
  margin-right: 10px;
}

*.rs-loader-backdrop {
  background-color: rgba(240, 240, 240, 0.5);
}

.form-group .p-calendar {
  width: 100%;
  height: 60px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  border-radius: 0.5px;
  margin-bottom: 15px;
}

.button-calendar button {
  background-color: #f7f7f7;
  border-radius: 1px;
  border: 1px solid #d1d5db;
  width: 50px;
}

.form-group .ui-datepicker-trigger {
  font-size: 24px;
}

*.p-datepicker .p-monthpicker {
  font-size: 12px !important;
}

*.p-datepicker-year {
  font-size: 12px !important;
}

#pr_id_2_panel {
  width: 230px !important;
  min-width: 200px !important;
  font-size: 12px !important;
}

#pr_id_3_panel {
  width: 230px !important;
  min-width: 200px !important;
  font-size: 12px !important;
}

@media screen and (max-width:320px) {
  .filterBy {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .filterLabel {
    flex: 0 0 10px;
  }

  .filterSelect {
    .builder.form-group select {
      border-color: #dbdbdbd4;
      height: 36px;
      border-radius: 5px;
      box-shadow: 0 0 2px rgb(0 0 0 / 11%);
    }

    flex: 0 0 10px;
    padding-right: 8px;
  }

  .validationMessage {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #b20633;
    padding-right: 17px;
  }

  .dailyFilterLabel {
    flex: 0 0 10px;
    padding-bottom: 0px !important;
    text-align: right;
    padding-right: 5px;
  }

  .dailyFilterSelect {
    .builder.form-group select {
      border-color: #dbdbdbd4;
      height: 26px;
      border-radius: 5px;
      box-shadow: 0 0 2px rgb(0 0 0 / 11%);
    }

    *.rs-picker-toggle-wrapper {
      width: 100%;
      border-radius: 0;
      min-width: 100%;
      max-width: 100%;
      min-height: 0px !important;
      margin-bottom: 5px !important;
      border: none !important;
    }
    width: 100%;
    flex: 0 0 10px;
  }
}

.dailyAirlineFilterSelect {
  .builder.form-group select {
    border-color: #dbdbdbd4;
    height: 36px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 11%);
  }

  *.rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-width: 230px;
    max-width: 230px;
    min-height: 0px !important;
    margin-bottom: 12px !important;
    border: none !important;
  }

  flex: 0 0 130px;
  margin-right: 10px;
}


@media screen and (min-width: 321px) and (max-width:620px) {
  .filterBy {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .filterLabel {
    flex: 0 0 10px;
  }

  .filterSelect {
    .builder.form-group select {
      border-color: #dbdbdbd4;
      height: 36px;
      border-radius: 5px;
      box-shadow: 0 0 2px rgb(0 0 0 / 11%);
    }

    flex: 0 0 10px;
    padding-right: 8px;
  }

  .validationMessage {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #b20633;
    padding-right: 17px;
  }

  .dailyFilterLabel {
    flex: 0 0 10px;
    padding-bottom: 0px !important;
    text-align: right;
    padding-right: 5px;
  }

  .dailyFilterSelect {
    .builder.form-group select {
      border-color: #dbdbdbd4;
      height: 26px;
      border-radius: 5px;
      box-shadow: 0 0 2px rgb(0 0 0 / 11%);
    }

    *.rs-picker-toggle-wrapper {
      width: 100%;
      border-radius: 0;
      min-width: 100%;
      max-width: 100%;
      min-height: 0px !important;
      margin-bottom: 5px !important;
      border: none !important;
    }
    width: 100%;
    flex: 0 0 10px;
  }
}

.dailyAirlineFilterSelect {
  .builder.form-group select {
    border-color: #dbdbdbd4;
    height: 36px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 11%);
  }

  *.rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-width: 230px;
    max-width: 230px;
    min-height: 0px !important;
    margin-bottom: 12px !important;
    border: none !important;
  }

  flex: 0 0 130px;
  margin-right: 10px;
}


.label {
  .base-font;
  font-size: 16px;
  font-weight: 400;
  color: @brand-dark-gray2;
  padding: 0 @form-label-padding;
  margin-bottom: 10px;
}

.small-label {
  .base-font;
  font-size: 13px;
  font-weight: normal;
  color: @brand-dark-gray2;
  padding: 0 @form-label-padding;
  margin-top: 20px;
  margin-bottom: 5px;
  display: inline-block;
}

.input,
.submit,
.submitDraft {
  .field-theme;
  width: 100%;
  padding: @form-field-padding;
}

.input {
  color: @brand-input-gray;
  background-color: #fff;
  margin-bottom: 15px;
  height: @form-field-height;
}

input + .form-error,
.note + .form-error {
  margin-top: -10px;
}

input[type="radio"],
input[type="checkbox"],
.checkbox {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0 5px 0 -20px;
}

input[type="radio"] {
  margin: 0 10px 10px 0;
}

.campaign-form .checkbox-wrap,
.campaign-form .radio-wrap,
.campaign-form .custom-radio-label,
.checkbox-wrap,.radio-wrap, .custom-radio-label {
  padding: 0 20px 5px;
  font-weight: 300;
  font-size: 18px;
  color: #646464;
  line-height: 1.35;
  vertical-align: auto;
  border-radius: 0;
  margin: 0;
  display: inline-block;
  text-align: unset;
  white-space: normal;
}

.campaign-form label.radio-wrap {
  cursor: pointer;
}

.campaign-form .radio-images {
  input {
    visibility: hidden;
    position: absolute;
  }

  input:not(:checked) {
    + .radio-wrap {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}

textarea {
  .field-theme;
  width: 100%;
  max-width: 100%;
  padding: @form-field-padding;
  height: auto;
  line-height: initial;
}

.select-container {
  .field-theme;
  position: relative;
  margin-bottom: 30px;

  select {
    .input;
    margin: 0;
    border: none;
    outline: none;
    display: inline-block;
    appearance: none;
    cursor: pointer;
  }

  &:before,
  &:after {
    position: absolute;
    pointer-events: none;
  }

  &:after {
    content: '\25BC';
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    color: #aaa;
    right: @form-field-padding;
    top: 50%;
    margin-top: -8px;
  }

  &:before {
    content: '';
    right: 1px;
    top: 1px;
    width: (@form-field-height - 2);
    height: (@form-field-height - 2);
    background: #fff;
    display: block;
  }
}

.select-container + .form-error {
  margin-top: -25px;
}

.submit {
  font-size: 25px;
  color: #fff;
  margin: 15px auto;
  min-height: @form-field-height;
  transition: @transition-default;
  .button-rollover(@brand-primary);
}

.submitDraft {
  font-size: 25px;
  color: #fff;
  margin: 15px auto;
  min-height: @form-field-height;
  transition: @transition-default;
  .button-rollover(@brand-secondary);
}

.submitButtonContainer {
  position: relative;

  .dropper {
    border: solid #dfdfdf;
    border-width: 0 0 0 1px;
    color: white;
    height: 60px;
    line-height: 60px;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 16px;
    width: 60px;

    &:after {
      content: '\25BC';
    }
  }

  .statusOptions {
    background: white;
    display: none;
    position: relative;
    top: -15px;

    .option {
      border: 1px solid #dfdfdf;
      border-width: 0 1px 1px;
      padding: 20px 50px;
      position: relative;

      .checked {
        height: 70px;
        left: 0;
        line-height: 70px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 50px;

        &:after {
          content: '\2713';
        }
      }

      .pill {
        background-color: #f0f0f0;
        border-radius: 5px;
        display: inline-block;
        margin: 0 5px;
        padding: 2px 8px;
      }

      .subtext {
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
      }
    }
  }
}

.form-footer {
  .base-font;
  border-top: 1px solid fade(@color-border, 17%);
  padding: 40px 0;
  margin: 0 15px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 30px 0;
  }
}

.form-error {
  .base-font;
  font-size: 16px;
  color: @color-error;
  margin-bottom: 15px;
}

.google-maps-container {
  height: 500px;
  margin-bottom: 10px;

  .map {
    height: 100%;
  }
}

.google-maps-search {
  width: 50%;
  height: 30px;
  font-size: 14px;
  margin: 10px;
  padding-top: 0;
  padding-bottom: 0;
}

// Custom forms

.body-form {
  .form-container {
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 8px auto;
    max-width: 800px;
  }

  .form-title {
    margin-bottom: 40px;
    text-align: center;
  }

  .view-title {
    color: @brand-light-gray3;
    font-family: @font-family-sans;
    font-size: 26px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .module {
    padding: 10px;
    background-color: #f3f3f3;
    margin-bottom: 10px;
  }

  .module-title {
    color: @brand-light-gray3;
    font-family: @font-family-sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .submit-button {
    width: 100%;
    text-align: center;
    border: none;
    margin-bottom: 10px;
    height: 66px;
    border-radius: 2px;
    color: white;
    font-size: 24px;
    line-height: 29px;
  }

  .two-column-container {
    padding-top: 10px;
    padding-bottom: 40px;
    margin: 8px;
    border-radius: 6px;
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.31);
    background-color: #fff;
  }

  .column-1, .column-2 {
    padding: 20px;
  }

  .column-2 {
    border-top: 1px solid #f1f1f1;
  }
}

.body-form.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

label.required:after,
.label.required:after,
.form-group-label.required:after {
  content: '*';
  margin-left: 4px;
  font-size: 20px;
}

.elem .spacer {
  background-color: transparent;
}

@media (min-width: @screen-md-min) {
  .body-form {
    .two-column-container {
      width: 80%;
      margin: 30px auto;
    }

    .column-container {
      display: table;
      width: 100%;
    }

    .column-1, .column-2 {
      display: table-cell;
      vertical-align: top;
      padding: 20px;
    }

    .column-1 {
      width: 70%;
    }

    .column-2 {
      width: 30%;
      border-left: 1px solid #f1f1f1;
      border-top: none
    }

    .form-container {
      > form {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}

.clickable.glyphicon.glyphicon-remove {
  border: 0;
  color: #d75252;
  background-color: transparent;
}

// Re-map form
.remap-container {
  background: #fafafa;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;

  .remap-name {
    font-size: 14px;
    font-weight: bold;
  }

  .remap-alias {
    font-size: 10px;
  }

  select, input {
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 200px;
    padding: 10px;
  }

  input {
    margin-top: 5px;
  }

  &.remap-container {
    margin-top: 5px;
  }
}
