.date-box {
  border: 1px solid #e1e2e1;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: #e9eae9;
  min-width: 230px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }

  .title {
    color: #5f636b;
    font-weight: 600;
    margin: 2px 0;
  }
}

