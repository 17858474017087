.campaingList {
  .campaign-list-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0;

    .search-bar {
      background-color: #fff;
      margin-right: 15px;
    }

    .glyphicon-search {
      color: #494e57;
    }

    .navigation-button {
      margin-left: 15px;
      background-color: #04ca9a;

      &:hover {
        background-color: #01bd8f;
      }
    }
  }

  *.rs-table-cell-content {
    white-space: normal;
    word-wrap: break-word;
  }

  *.rs-btn-link {
    color: #4d6cef;
    font-weight: 400;
    font-size: 15px;
  }

  .revision {
    padding: 0px 12px;
    font-size: smaller;
    display: flex;
    color: #666;
    margin-top: -9px;
  }

  .revision-name {
    padding: 7px 12px;
    color: #4d6cef;
    font-weight: 400;
    font-size: 15px;
  }
  
  .revision .revision-number {
    margin-left: 4px;
  }

  // FIXME: This is temporary solution
  .campaign-loading {
    position: absolute;
    top: 12px;
    left: 60px;
  }

  .full-width-panel {
    background-color: #f0f0f0;
    padding: 0;
  }

  .data-table table {
    th {
      background-color: #e6e6e6;
      font-size: 13px;
      letter-spacing: .5px;
      font-weight: 400;
    }

    tr {
      font-size: 15px;
      box-shadow: none;
      border: 1px solid #dedede;

      &:nth-child(2n+1) {
        background-color: #f0f0f0;
      }

      &:hover:not(.header),
      &.selected {
        background-color: #e6e6e6;
        color: #333;

        td.name {
          color: #333;
        }

        .glyphicon-option-horizontal {
          color: #979797;
        }

        .revision-subtitle {
          color: #666;
        }
      }

      &.selected {
        .name:before {
          content: '';
          position: absolute;
          left: -1px;
          top: -1px;
          bottom: -1px;
          width: 5px;
          background-color: #4d6cef;
        }
      }
    }

    td {
      font-weight: 300;

      &.name {
        color: #494e57;
        font-weight: 400;
      }
    }

    .action-button {
      font-size: 1em;
      margin: -16px 8px 0;

      &.glyphicon-trash {
        color: #0b0a0a;

        &.active {
          background-color: #f33f17;
          color: #fff;
          transition: none;

          &:hover {
            background-color: darken(#f33f17, 20%);
          }
        }
      }
    }
  }

  .campaign-selector {
    .campaign-table {
      position: relative;
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  td {
    &.name {
      min-width: 230px;
    }
    &.recipientsRange {
      width: 170px;
      min-width: 130px;
    }
    &.created {
      width: 150px;
      min-width: 115px;
    }
    &.updated {
      width: 250px;
      min-width: 210px;
    }
    &.totalSubmissions {
      width: 140px;
      min-width: 115px;
    }
  }

  .data-table .action-column {
    width: 145px;
    min-width: 115px;
  }
}
