.country-choice {
  display: block;
  padding: 10px;

  .glyphicon.glyphicon-star {
    margin-right: 10px;
    top: 3px;

    &.favorite {
      color: gold
    }
  }

  input {
    margin-right: 10px;
  }
}

.one-line {
  display: block;

  > * {
    display: inline-block;
    margin-right: 20px;
  }
}

.country-list {
  column-count: 3;
}

.hint {
  font-size: 0.8em;
  margin-top: 10px;
  color: #828282;
}
