@w-textarea:~ "w-tc-editor";

.@{w-textarea} {
  --color-input-border: #dfdfdf;
  --color-fg-default: #6d71a3;
  --color-canvas-subtle: #fff;
  --color-prettylights-syntax-comment: #b7b9bc;
  --color-prettylights-syntax-entity-tag: #4c8cfe;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant: #aba6c2;
  --color-prettylights-syntax-string: #e79121;
  --color-prettylights-syntax-keyword: #c162bf;
  --color-prettylights-syntax-markup-bold: #6d71a3;
}

.@{w-textarea} {
  font-size: 13px;
  line-height: 1.4;
  font-weight: 600;
  border: 1px solid var(--color-input-border);
  border-bottom: 0 none;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-subtle);
  color: var(--color-fg-default);
  &-text,
  &-preview {
    min-height: 16px;
  }
  &-preview {
    pre {
      color: var(--color-fg-default);
      background-color: transparent;
      border: 0 none;
      line-height: 1.4;
      margin: 0;
      padding: 0;
      white-space: inherit;
      font-family: inherit;
      font-size: inherit;
      code {
        font-family: inherit;
      }
    }
  }

  code[class*='language-'],
  pre[class*='language-'] {
    .code-line {
      white-space: pre-wrap;
      word-break: keep-all;
      overflow-wrap: break-word;
    }

    .token.cdata,
    .token.comment,
    .token.doctype,
    .token.prolog {
      color: var(--color-prettylights-syntax-comment);
    }
    .token.punctuation {
      color: var(--color-prettylights-syntax-sublimelinter-gutter-mark);
    }
    .namespace {
      opacity: 0.7;
    }

    .token.boolean,
    .token.constant,
    .token.deleted,
    .token.number,
    .token.symbol {
      color: var(--color-prettylights-syntax-entity-tag);
    }

    .token.builtin,
    .token.char,
    .token.inserted,
    .token.selector,
    .token.string {
      color: var(--color-prettylights-syntax-string);
    }

    .style .token.string,
    .token.entity,
    .token.property,
    .token.operator,
    .token.url {
      color: var(--color-prettylights-syntax-constant);
    }

    .token.atrule,
    .token.property-access .token.method,
    .token.keyword {
      color: var(--color-prettylights-syntax-keyword);
    }

    .token.function {
      color: var(--color-prettylights-syntax-string);
    }

    .token.important,
    .token.regex,
    .token.variable {
      color: var(--color-prettylights-syntax-string-regexp);
    }

    .token.bold,
    .token.important {
      color: var(--color-prettylights-syntax-markup-bold);
    }
    .token.tag {
      color: var(--color-prettylights-syntax-entity-tag);
    }
    .token.attr-value,
    .token.attr-name {
      color: var(--color-prettylights-syntax-constant);
    }
    .token.selector .class,
    .token.class-name {
      color: var(--color-prettylights-syntax-entity);
    }
  }
}
