// TODO: Split classes into files per field
.no-field {
  margin-top: 20px;
}

.builder.form-group {
  textarea.code-editor {
    // Styles copied from Ace JSON editor, as used by JSON field
    font-family: 'dejavu sans mono', 'droid sans mono', consolas, monaco, 'lucida console', 'courier new', courier, monospace, sans-serif;
    font-size: 13px;
    line-height: 1.3;
  }
}

.box-edition {
  padding: 15px;
  background: #dedede;
}

.remove-checkbox {
  float: right;
  color: #d01c34;
  cursor: pointer;
}

.image-uploader {
  .file-preview {
    margin-top: 15px;
  }

  .image-preview-error {
    color: #d01c34;
  }
}

.spacer {
  &.space-10 {
    height: 10px;
  }

  &.space-20 {
    height: 20px;
  }

  &.space-30 {
    height: 30px;
  }

  &.space-40 {
    height: 40px;
  }

  &.space-50 {
    height: 50px;
  }

  &.space-60 {
    height: 60px;
  }

  &.space-100 {
    height: 100px;
  }
}

.editor-button {
  .glyphicon;
  font-size: 0.8em;
  background: #fff;
  border: 1px solid @form-field-border-color;
  border-radius: @form-field-border-radius;
  padding: 10px;
  margin: 0 5px 5px 0;

  &.bold {
    .glyphicon-bold;
  }

  &.italic {
    .glyphicon-italic;
  }

  &.header {
    .glyphicon-header;
  }

  &.listUl {
    .glyphicon-list;
  }

  &.listOl {
    .glyphicon-th-list;
  }

  &.link {
    .glyphicon-link;
  }

  &.linkImage {
    .glyphicon-picture;
  }

  &.codeBlock {
    .glyphicon-console;
  }

  &.preview {
    .glyphicon-eye-open;
  }

  &.markdown {
    .glyphicon-pencil;
  }
}

.markdown-preview {
  margin-top: 20px;

  img {
    max-width: 100%;
  }
}

.photo-selector-container {
  .movable,
  .thumbnail-wrap {
    position: relative;
    display: inline-flex;
    width: 25%;
    height: 100px;
    vertical-align: middle;
  }

  .thumbnail-wrap,
  .glyphicon {
    cursor: pointer;
  }

  .thumbnail {
    width: 100%;
    margin: 3px;
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.active {
      opacity: .4;
    }
  }

  .thumbnail-icons {
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 3px 3px 0 3px;
    background: #fff;
    transition: @transition-default;

    .glyphicon {
      transition: @transition-default;
    }

    .glyphicon-trash {
      &:hover {
        color: #0b0a0a;
      }
    }
  }

  .selected-photos {
    margin-top: 20px;

    .thumbnail-wrap {
      width: 100%;
      cursor: inherit;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.experiences-list {
  .item {
    margin: 0 5px 5px 0;

    &.active {
      background-color: #adadad;
    }
  }
}

.calendar-event {
  .label-inline {
    display: inline-block;
    width: 85px;
    padding-right: 10px;
    text-align: right;
    vertical-align: middle;
  }

  .label-offset {
    padding-left: 85px;
  }

  .rdtPicker {
    margin-top: -15px;
  }

  .date,
  .time {
    display: inline-block;
  }

  .date {
    width: 200px;

    .rdtPicker {
      margin-left: -50px;
    }

    @media (min-width: @screen-xs-min) {
      width: 160px;

      .rdtPicker {
        margin-left: 0;
      }
    }
  }

  .time {
    width: 200px;
    margin-left: 85px;

    .rdtPicker {
      width: 200px;
    }

    @media (min-width: @screen-xs-min) {
      width: 140px;
      margin-left: 10px;

      .rdtPicker {
        width: 140px;
      }
    }
  }

  .all-day {
    display: inline-block;
    vertical-align: middle;
    margin-top: -7px;
  }

  .repeat-until {
    margin-left: 85px;

    @media (min-width: @screen-xs-min) {
      margin-left: 10px;
    }
  }

  .calendar-button {
    display: flex;
    float: left;
    width: 2em;
    height: 2em;
    margin-top: -1px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border: 1px solid @form-field-border-color;
    cursor: pointer;

    &.selected {
      background-color: #e6e6e6;
    }
  }

  .calendar-wrap {
    overflow: auto;
    padding: 10px 0;

    @media (min-width: @screen-xs-min) {
      padding: 10px 0 10px 25px;
    }

    .weekly-days {
      margin-top: 10px;
      max-width: 200px;

      @media (min-width: @screen-xs-min) {
        margin-left: 35px;
        margin-top: -29px;
      }
    }

    &.month {
      margin-left: -53px;

      @media (min-width: @screen-xs-min) {
        margin-left: 0;
      }
    }
  }

  .floating-date {
    padding: 10px 0 0 25px;

    @media (min-width: @screen-xs-min) {
      margin-left: -25px;
    }
  }

  .week {
    padding-left: 0;

    .calendar-button {
      width: 2.5em;
    }
  }

  .month {
    .calendar-button {
      &:nth-child(7n+8) {
        clear: left;
      }
    }
  }

  .year {
    .calendar-button {
      width: 2.5em;
      height: 2.5em;

      &:nth-child(4n+5) {
        clear: left;
      }
    }
  }

  .custom-event {
    .label-offset {
      margin-bottom: 10px;
    }
  }

  .input,
  .select-container {
    display: inline-block;
    height: 40px;
    padding: 0 15px;
    margin-bottom: 10px;
    vertical-align: middle;
  }

  .select-container {
    width: 200px;
    background: rgba(255, 255, 255, .5);

    @media (min-width: @screen-xs-min) {
      width: 310px;
    }

    &:before {
      width: 38px;
      height: 38px;
      background: transparent;
    }

    select {
      height: 40px;
      padding: 0;
      background-color: transparent;
    }
  }

  .custom-frequency {
    width: 120px;

    @media (min-width: @screen-xs-min) {
      width: 160px;
    }
  }

  .interval {
    width: 70px;
    margin-right: 10px;

    @media (min-width: @screen-xs-min) {
      width: 140px;
    }
  }

  .end-after {
    width: 70px;
    margin-left: 85px;
    margin-right: 10px;

    @media (min-width: @screen-xs-min) {
      width: 80px;
      margin-left: 10px;
    }
  }

  .end-after-text {
    display: inline-block;
    vertical-align: middle;
    margin-top: -14px;
  }

  @media (min-width: @screen-xs-min) {
    .end-repeat {
      width: 140px;
    }
  }

  .floating-week {
    width: 175px;
    margin-right: 10px;

    @media (min-width: @screen-xs-min) {
      width: 140px;
    }
  }

  .floating-day {
    width: 175px;

    @media (min-width: @screen-xs-min) {
      width: 160px;
    }
  }
}

.file-uploader-formats {
  .builder.form-group {
    display: inline-block;
    margin: 0 20px 0 0;
  }
}
