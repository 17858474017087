.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: @brand-dark-gray2;
  font-size: 14px;
  font-weight: 400;
  margin-right: 35px;
  cursor: pointer;

  > span {
    margin-left: 11px;
  }

  .glyphicon-menu-down {
    font-size: 12px;
  }

  .dropdown-toggle {
    .glyphicon {
      margin-left: 3px;
    }
  }
}

.dropdown-menu {
  min-width: 140px;
  position: absolute;
  top: 100%;
  left: auto;
  right: -17px;
  margin-top: 15px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.41);
  display: none;
  white-space: nowrap;
  border: none;
  border-radius: 0;

  &:before,
  &:after {
    position: absolute;
    bottom: 100%;
    right: 18px;
    border: 6px solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    margin-left: -6px;
    pointer-events: none;
  }

  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.38);
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;

  }

  .glyphicon {
    top: 2px;
  }

  a {
    display: block;
    padding: 7px 13px;
    text-decoration: none;
    color: #5d5d5d;
    transition: @transition-default;

    &:hover {
      background-color: #f0f0f0;
    }

    > p {
      display: inline;
      margin: 0;
      padding-left: 10px;
    }
  }
}
