.box-overview {
  .titel {
    color: #5f636b;
    font-weight: 400;
    margin: 2px 0;
  }

  .block-overview {
    background-color: #fff;
    padding: 20px 15px;
  }
}
