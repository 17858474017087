.tabs {
  padding: 30px 0 5px;

  .tab {
    font-family: @font-family-sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2em;
    color: @brand-dark-gray2;
    padding: 10px 10px 5px;
    margin: 0 10px 0 0;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &.active {
      border-bottom: 2px solid @brand-primary;
    }
  }
}

.tabs-container {

  .tab-content {
    display: none;

    &.active {
      display: inherit;
    }
  }
}
