.data-source-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;

  .navigation-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}
