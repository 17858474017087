.image-selector-library {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  z-index: 101;
  overflow: auto;

  .header.-fixed {
    position: fixed;
    background: #FFFFFF;
    z-index: 102;
  }

  .image-selector-library-header {
    font-size: 24px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    margin-left: 30px;
    font-weight: 600;

    .close {
      float: none;
      font-size: 32px;
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 50px;
    }

    .image-selector-library-nav {
      font-size: 18px;
      margin-right: 16px;
    }
  }

  #imageSelectorHr {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .confirmselection {
    padding-left: 32px;
    padding-bottom: 13px;

    .cancel-selection-button {
      margin-left: 16px;
    }

    .search-bar {
      padding-left: 0px;
      width: 550px;
    }
  }

  #imageSelectorSearch {
      width: 500px;
  }

  .confirm-related-component {
    padding-left: 32px;
    padding-bottom: 13px;

    .cancel-selection-button {
      margin-left: 16px;
    }

    .search-bar {
      padding-left: 0px;
      width: 550px;
    }
  }

  .image-selector-library-content {
    margin-top: 192px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 32px;
    gap: 16px;

    @media (min-width: @screen-sm-min) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: @screen-md-min) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: @screen-lg-min) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1400px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (min-width: 1800px) {
      grid-template-columns: repeat(8, 1fr);
    }

    .item {
      width: 100%;
      position: relative;
      box-sizing: border-box;

      img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16 / 9;
      }

      .image-selector-library-checkbox {
          position: absolute;
          top: 12px;
          right: 12px;
      }
    }
  }
}

.imageSelectorPaginator {
  text-align: right;
  margin-right: 75px;
  margin-bottom: 10px;
}

.related-component-paginator {
  text-align: right;
  margin-right: 10px;
  margin-bottom: 15px;
}

.image-selector-library-preview {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100vw;
  height: 100vh;
  z-index: 104;
  overflow: hidden;
  display: flex;

  .image {
    width: 60%;
    height: 100%;
    background: #000000;
    padding: 64px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.image-selector-library-preview-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 32px;

  .image-selector-library-preview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .close {
      font-size: 32px;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .info {
    flex-grow: 1;
    margin-top: 200px;
  }
}

.image-selector-library-preview-info-item {
  margin-bottom: 16px;
}

.image-selector-library-upload {
  padding: 32px;

  .notlatestrevision {
    font-size: 24px;
  }
}

