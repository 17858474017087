@transition-duration: 0.6s;
@compact-width: 80px;
@expanded-width: 230px;

.icon-button {
  vertical-align: middle;
  height: 28px;
  width: 28px;
  line-height: 25px;
  font-size: 25px
}

.navigation-button {
  vertical-align: top;
  padding-right: 22px;
  padding-left: 22px;
  height: 38px;
}

.icon-button,
.navigation-button {
  border-radius: 4px;
  border: none;
  display: inline-block;
  color: #fff;
  .button-rollover(@brand-blue);

  &.primary {
    color: #fff;
    background: #04ca9a;
    border: 1px solid #04ca9a;

    &:hover {
      background: #01bd8f;
      border-color: #01bd8f;
    }
  }

  &.secondary {
    color: #444;
    background: #ddd;
    border: 1px solid #ccc;

    &:hover {
      background: rgba(153, 153, 153, 0.47);
      border-color: #ccc;
    }
  }

  &.danger {
    color: #fff;
    background: #c75028;
    border: 1px solid #ca5104;

    &:hover {
      background: #ca3504;
      border-color: #ca3504;
    }
  }

  &.accent {
    border: 1px solid #979797;
    background: transparent;
    color: #494e57;

    &.active,
    &:hover {
      color: #01bd8f;
      border-color: #01bd8f;
    }
  }
}

.layout {
  height: 100%;

  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .logo-accelerator {
      height: 80px;
      display: inline-block;
      vertical-align: top;
      transition: width @transition-duration;
    }

    .nav-bar {
      display: inline-block;
      vertical-align: top;
      height: 80px;
      background-color: white;
      padding-top: 26px;
      transition: width @transition-duration;
      box-shadow: 0px -5px 10px 0px #808080;

      a.logo-expedia {
        display: inline-block;
        margin-left: 18px;
      }

      > div {
        float: right;
      }
    }
  }

  .left-menu {
    background-color: #142433;
    position: fixed;
    left: 0;
    top: 80px;
    bottom: 0;
    z-index: 100;
    transition: width @transition-duration;

    .icon-container {
      display: flex;
      flex-direction: row;
      text-decoration: none;

      .icon {
        height: 80px;
        width: 80px;
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: no-repeat center;
        &.menu {
          background-image: url('@{icons-path}/menu.png');
        }
        &.dashboard {
          background-image: url('@{icons-path}/perf.png');
        }
        &.campaign {
          background-image: url('@{icons-path}/campaign.png');
        }
        &.view {
          background-image: url('@{icons-path}/view.png');
        }
        &.module {
          background-image: url('@{icons-path}/module.png');
        }
        &.field {
          background-image: url('@{icons-path}/field.png');
        }
        &.settings {
          background-image: url('@{icons-path}/settings.png');
        }
        &.mailer {
          background-image: url('@{icons-path}/mailer.png');
        }
        &.more {
          background-image: url('@{icons-path}/more.png');
        }
        &.data-source {
          background-image: url('@{icons-path}/data-source.png');
        }
        &.audit-logs {
          background-image: url('@{icons-path}/audit-logs.png');
        }
      }
      .text {
        color: #9a9ea1;
        display: flex;
        align-items: center;
        overflow-x: hidden;
        white-space: nowrap;
        transition: width @transition-duration;
      }

      &.selected, &:hover {
        .icon {
          &.menu {
            background-image: url('@{icons-path}/menu-selected.png');
          }
          &.dashboard {
            background-image: url('@{icons-path}/perf-selected.png');
          }
          &.campaign {
            background-image: url('@{icons-path}/campaign-selected.png');
          }
          &.view {
            background-image: url('@{icons-path}/view-selected.png');
          }
          &.module {
            background-image: url('@{icons-path}/module-selected.png');
          }
          &.field {
            background-image: url('@{icons-path}/field-selected.png');
          }
          &.settings {
            background-image: url('@{icons-path}/settings-selected.png');
          }
          &.mailer {
            background-image: url('@{icons-path}/mailer-selected.png');
          }
          &.more {
            background-image: url('@{icons-path}/more-selected.png');
          }
          &.data-source {
            background-image: url('@{icons-path}/data-source-selected.png');
          }
          &.audit-logs {
            background-image: url('@{icons-path}/audit-logs-selected.png');
          }

        }
        .text {
          color: #fff;
        }
      }
      &.selected {
        background-color: @brand-blue;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.36);
      }
    }
  }

  .content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 80px;
    transition: all @transition-duration;
    height: 100vh;
    background-color: @brand-light-gray1;
    overflow-x: hidden;
    min-width: 1000px;
  }
}

.layout.compact {
  .topbar {
    .logo-accelerator {
      background: @brand-blue url('@{assets-path}/accelerator-logo-1.png') center no-repeat;
      width: @compact-width;
    }

    .nav-bar {
      width: ~'calc(100% - @{compact-width})';
    }
  }

  .left-menu {
    width: @compact-width;

    .icon-container {
      .text {
        width: 0;
      }
    }
  }

  .content {
    margin-left: @compact-width;
    width: ~'calc(100% - @{compact-width})';
  }
}

.layout.expanded {
  .topbar {
    .logo-accelerator {
      background: @brand-blue url('@{assets-path}/accelerator-logo-2.png') center no-repeat;
      width: @expanded-width;
    }

    .nav-bar {
      width: ~'calc(100% - @{expanded-width})';
    }
  }

  .left-menu {
    width: @expanded-width;

    .icon-container {
      .text {
        width: @expanded-width - @compact-width;
      }
    }
  }

  .content {
    margin-left: @expanded-width;
    width: ~'calc(100% - @{expanded-width})';
  }
}

.hidden {
  display: none;
}

// Specific case in FormBuilder
.alert + .layout .content {
  padding-top: 0;
}

.title-btn-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items-bar-wrap {
  display: flex;
  align-items: center;
  padding: 20px 0;

  .wrap-item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
