@skin-color-green: #8cb600;

.green {
  &.navigation-button {
    .button-rollover(@skin-color-green);
  }

  .items-per-page-container {
    .selector {
      border-color: @skin-color-green;
    }
  }

  &.finalise {
    color: @skin-color-green;
  }
}
