.home-page {
  background: linear-gradient(to bottom, @brand-primary 0%, fade(@brand-primary, 0%) 100%),
          url('@{assets-path}/home-page-background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .title-wrap {
    text-align: center;
  }

  .title {
    text-align: center;
    padding: 40px 15px 0;
    margin: 0 auto;
    max-width: 750px;

    .form-title,
    .form-subtitle {
      color: @brand-white;
    }
  }
}
