.page-title {
  color: @brand-dark-gray2;
  font-size: 29px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -1px;
  margin-top: 20px;
  margin-bottom: 20px;

  &.subtitle {
    color: @brand-primary;
    font-size: 22px;
    margin-bottom: 10px;
  }
}
