.chart-component-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 14px;

  .chart-component-label {
    font-size: inherit;
    font-weight: bold;
    margin: 0 4px 0 0;
  }
}

.chart-component-source-label {
  display: block !important;
  text-align: center !important;
  padding-top: 5px !important;
  font-size: 10px !important;
}

.chart-component-comparison {
  margin-right: auto;
}

.chart-component-comparison__multi-value,
.chart-component-comparison__single-value,
.chart-component-comparison__menu-list {
  font-weight: bold;
}

.chart-component-groups__multi-value,
.chart-component-groups__single-value,
.chart-component-groups__menu-list {
  font-weight: normal;
}

.chart-component-groups {
  margin-right: 5px;
}

.chart-component-groups__menu {
  right: 0;
}

.chart-component-comparison__multi-value:not(:first-child) {
  &::before {
    content: "vs";
    display: block;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.chart-component-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  transition: opacity 0.1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
  z-index: 99999999;

  &.above,
  &.right,
  &.left,
  &.center,
  &.below {
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
    }
  }

  &.center {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.right {
    &::before {
      right: 16px;
    }
  }

  &.centerLeft {
    &::before {
      right: 16px;
    }
  }

  &.left {
    &::before {
      left: 16px;
    }
  }

  &.above {
    &::before {
      top: 100%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 0.7);
    }
  }

  &.below {
    &::before {
      bottom: 100%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.7);
    }
  }

  td {
    text-align: left;
    font-weight: 400;
    white-space: nowrap;
  }

  &-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
}
