@travelocity-color: #f5772a;

.travelocity {
  .submit {
    background-color: @travelocity-color;
    &:hover {
      background-color: darken(@travelocity-color, 20%);
    }
  }

  .repeat-module {
    background-color: @travelocity-color;
    &:hover {
      background-color: darken(@travelocity-color, 20%);
    }
  }

  .campaign-form h2 {
    color: @travelocity-color;
  }

  .form-link {
    color: @travelocity-color;

    &:hover {
      color: darken(@travelocity-color, 20%);
    }
  }

  .progress {
    .progress-bar {
      background-color: @travelocity-color;
    }
  }
}
