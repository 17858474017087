.dashboard {
  margin-top: 40px;

  .loading {
    font-size: 17px;
    color: @brand-blue;
    top: 3px;
    left: 10px;
  }

  .submitted-data {
    margin-top: 40px;
  }

  .submitted-content {
    width: 100%;

    .field-wrapper {
      border-top: 1px solid @form-field-border-color;

      &:first-of-type {
        border-top: none;
      }
    }

    .field {
      vertical-align: top;
      padding: 5px 0;
    }

    .field-name {
      min-width: 250px;
      width: 250px;
      font-weight: bold;
      padding-right: 10px;
    }

    .field-input {

    }
  }

  .user-entry {
    padding: 50px 0;
    border-top: 1px solid @brand-light-gray3;

    &:first-of-type {
      border-top: none;
      padding-top: 0;
    }

    ul {
      list-style: none;
      padding: 0 10px;
    }

    li {
      line-height: 18px;
      margin-bottom: 20px;
      font-size: 17px;
    }

    .input-label {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }

    .short-content,
    .long-content {
      white-space: pre-wrap;
      padding-left: 20px;
    }

    .long-content {
      display: block;
    }

    .short-content {
      display: inline-block;
      vertical-align: top;
    }

    .button-container {
      text-align: right;

      .button {
        display: inline-block;
        padding: 5px 20px;
      }
    }
  }
}

table.dashboard-list {
  width: 100%;
  margin-top: 30px;

  tr {
    color: #494e57;
    font-size: 17px;
    line-height: 30px;
    cursor: pointer;

    &.expired {
      color: #d01c34;
      cursor: not-allowed;
    }
  }

  .left-aligned {
    text-align: left;
  }

  .right-aligned {
    text-align: right
  }
}
