.campaign-review {
  .item {
    margin-top: 15px;
    font-size: 16px;

    span.title {
      color: @brand-blue;
    }

    span.value {
      margin-left: 10px;
    }

    div.value {
      margin-top: 10px;
      padding: 5px;
      background-color: @brand-light-gray1;
    }
  }
}

.send-button {
  display: block;
  width: 100%;
  border: none;
  color: #fff;
  height: 70px;
  text-transform: uppercase;
  font-size: 18px;
  .button-rollover(@brand-blue);
}
