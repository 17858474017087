.view-title-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .view-title {
    margin-top: 10px;
  }
}

.view-title {
  color: @brand-blue;
  font-size: 22px;
  font-weight: 400;
  display: inline-block;
  vertical-align: top;
}

