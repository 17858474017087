@skin-color-orange: #ff8513;

.orange {
  .view-title {
    color: @skin-color-orange;
  }

  .navigation-button, &.navigation-button {
    .button-rollover(@skin-color-orange);
  }

  .items-per-page-container {
    .selector {
      border-color: @skin-color-orange;
    }
  }

  .data-table table thead {
    color: @skin-color-orange;
  }

  .builder.form-group input[type=text], .builder.form-group input[type=number], .builder.form-group textarea {
    border-color: @skin-color-orange;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn {
    width: 100%;
    border-color: @skin-color-orange;
    border-radius: 2px;
  }

  *.rs-picker-toggle-wrapper {
    width: 100%;
    border-color: @skin-color-orange;
    border-radius: 2px;
    padding-bottom: 0px;
  }

  *.rs-picker:not(.rs-picker-disabled):hover {
    border: 2px solid #005fcc;
    border-radius: 2px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn ~.rs-picker-tag-wrapper input {
    border: 0;
    padding-bottom: 0px;
  }

  .builder.form-group select {
    border-color: @skin-color-orange;
  }

  .search-bar span.glyphicon.loading {
    color: @skin-color-orange;
  }

  .actions > span.delete {
    color: @skin-color-orange;
  }

  .movable-view {
    border-color: @skin-color-orange;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    border-color: @skin-color-orange;
  }

  .location-suggest .loading {
    color: @skin-color-orange;
  }
}
