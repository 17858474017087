.items-per-page-container {
  .selector {
    border: 2px solid @brand-blue;
    border-radius: @form-field-border-radius;
    width: 60px;
    font-size: 14px;
    padding: 5px;
    background-color: #fff;
    height: 38px;
    margin-left: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
