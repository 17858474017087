// Specifics for the campaign form


// I don't know where to put the following
.submissions-container-loader {
  height: 500px;
}

.modal-preview {
  width:700px;
}

.campaign-layout {
  background-color: @brand-light-gray1;

  &.preview {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section {
    margin-top: 100px;
  }
}

.campaign-form {
  max-width: 655px;
  margin: 50px auto;
  .base-font;
  line-height: 135%;

  form {
    max-width: 600px;
    margin: 0 auto;
  }

  .image-preview {
    .field-theme;
    max-width: 100%;
  }

  h1 {
    .h1;
  }

  h2,
  .view-title {
    text-transform: uppercase;
    font-size: 24px;
    color: @brand-blue;
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  h3,
  .module-title {
    .h3;
  }

  .module-title {
    margin: 10px 0 20px;
    text-align: left;
  }

  .header-image-container {
    margin: 30px auto;
  }

  label,
  .form-group-label {
    .label;
    display: inline-block;
    text-align: left;
    white-space: normal;
  }

  .cols1 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 100%;
    }
  }

  .cols2 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 50%;
    }
  }

  .cols3 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: calc(100%/3);
    }
  }

  .cols4 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/4);
    }
  }

  .cols5 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/5);
    }
  }

  @media screen and (max-width:392px){
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 100%;
      }
    }
  }

  @media (min-width:393px) and (max-width:580px) {
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 50%;
      }
    }
  }

  .builder.form-group label {
    display: inline-block;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin: 0;
  }

  .field-list {
    position: relative;
    margin-top: 20px;
  }

  .field-list + .field-list {
    border-top: 1px dashed @color-border;
    padding-top: 40px;

    .repeat-delete-module {
      margin-top: 40px;
    }
  }

  .relatedComponentInput,
  .imageSelectorInput {
    width: 4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #dfe1de;
  }

  .rs-panel-body, .rs-panel-header {
    padding: 10px;
    line-height: 19px;
  }

  .rs-btn-toolbar{
    text-align: right;
  }

  #sequenceToolbar{
    text-align: left;
  }

  #sequenceIcon.rs-icon {
    background-color: #d3d3d7dd;
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-height: 60px;
    margin-bottom: 25px;
  }

  *.rs-picker:not(.rs-picker-disabled):hover {
    border: 2px solid #005fcc;
    border-radius: 2px;
  }

  *.ant-pagination-total-text {
    margin-right: 30px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-tag {
    margin-top: 16px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
  }

  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-picker-search-input {
    margin-top: 16px;
  }

  .rs-picker-tag .rs-picker-toggle {
    margin-top: 10px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper {
    margin-bottom: 10px;
  }

  .dragAndDropImageContainer {
    display: flex;
    touch-action: none;
    width: 99.2%;
    margin: 1rem auto;
  }

  .dropZoneImage {
    flex: 1;
    height: 88.5rem;
  }

  .dropZoneImage.image {
    margin-right: 10px;
  }

  .grid-item-image {
    width: 217px;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-image:hover {
    cursor: move;
  }

  .grid-item-image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .dragAndDropTextContainer {
    display: flex;
    touch-action: none;
    width: 64rem;
    margin: 1rem auto;
  }

  .dropZoneText {
    flex: 1;
    height: 56rem;
  }

  .dropZoneText.text {
    margin-right: 10px;
  }

  .grid-item-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .relatedComponentStyle-text,
  .imageSelectorStyle-text {
    max-height: 42em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-text-0,
  .imageSelectorStyle-text-0 {
    max-height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-0,
  .imageSelectorStyle-image-0 {
    height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-s,
  .imageSelectorStyle-image-s {
    height: 19em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-m,
  .imageSelectorStyle-image-m {
    height: 35.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-l,
  .imageSelectorStyle-image-l {
    height: 52em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-xl,
  .imageSelectorStyle-image-xl {
    height: 52em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .bar {
    background-color: #f0f0f0;
    min-height: 2.5em;
  }

  .sidebar-0 {
    margin-top: 1px;
  }

  .bar-0 {
    margin-top: 1px;
    background-color: none;
  }

  .rs-btn {
    color: #4d6cef;
    margin-bottom: 6px;
  }

  .rs-panel-bordered {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 15px;
    width: 95.2%;
  }

  .rs-panel-bordered-image {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    margin-left: 0px;
    display: inline-block;
    width: 100%;
  }

  .centered-image-added{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cfdcf4;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    font-size: 13px;
    color: #0b2497;
    font-weight: bold;
  }

  .textLayout {
    margin-bottom: 11px;
    text-align: justify;
  }

  .rs-panel-height {
    min-height: 45px;
  }

  .relatedComponentCheck {
    margin: 0 0px 0 10px !important;
    margin-top: 6px !important;
  }

  .ql-container {
    background-color: white;
    font-size: 16px;
    height: 26rem;
  }

  .ql-toolbar.ql-snow {
    background-color: #e9e9e9;
  }

  .terms {
    margin: 50px 0 10px;
    text-align: center;
  }

  .terms-container {
    display: inline-block;
    text-align: left;

    .form-error {
      margin-left: -20px;
    }
  }

  .terms + .terms {
    margin-top: 0;
  }

  .length-counter {
    font-size: 0.8em;
    font-style: italic;
    margin-top: -5px;
  }

  .length-counter + .form-error,
  .submit + .form-error {
    margin-top: -10px;
  }

  input + .length-counter {
    margin-top: -15px;
  }

  .submitting-status {
    text-align: center;
    vertical-align: middle;

    .loading {
      margin-right: 8px;
    }
  }
}

.image-container-added {
  position: relative;
  display: inline-block;
  text-align: center;
  opacity: 0.8;
  width: 100%;
}

.campaign-form-small {
  max-width: 655px;
  margin: 50px auto;
  .base-font;
  line-height: 135%;

  form {
    max-width: 600px;
    margin: 0 auto;
  }

  .image-preview {
    .field-theme;
    max-width: 100%;
  }

  h1 {
    .h1;
  }

  h2,
  .view-title {
    text-transform: uppercase;
    font-size: 24px;
    color: @brand-blue;
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  h3,
  .module-title {
    .h3;
  }

  .module-title {
    margin: 10px 0 20px;
    text-align: left;
  }

  .header-image-container {
    margin: 30px auto;
  }

  label,
  .form-group-label {
    .label;
    display: inline-block;
    text-align: left;
    white-space: normal;
  }

  .cols1 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 100%;
    }
  }

  .cols2 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 50%;
    }
  }

  .cols3 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: calc(100%/3);
    }
  }

  .cols4 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/4);
    }
  }

  .cols5 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/5);
    }
  }

  @media screen and (max-width:392px){
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 100%;
      }
    }
  }

  @media (min-width:393px) and (max-width:580px) {
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 50%;
      }
    }
  }

  .builder.form-group label {
    display: inline-block;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin: 0;
  }

  .field-list {
    position: relative;
    margin-top: 20px;
  }

  .field-list + .field-list {
    border-top: 1px dashed @color-border;
    padding-top: 40px;

    .repeat-delete-module {
      margin-top: 40px;
    }
  }

  .relatedComponentInput,
  .imageSelectorInput {
    width: 4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #dfe1de;
  }

  .rs-panel-body, .rs-panel-header {
    padding: 10px;
    line-height: 19px;
  }

  .rs-btn-toolbar{
    text-align: right;
  }

  #sequenceToolbar{
    text-align: left;
  }

  #sequenceIcon.rs-icon {
    background-color: #d3d3d7dd;
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-height: 60px;
    margin-bottom: 25px;
  }

  *.rs-picker:not(.rs-picker-disabled):hover {
    border: 2px solid #005fcc;
    border-radius: 2px;
  }

  *.ant-pagination-total-text {
    margin-right: 30px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-tag {
    margin-top: 16px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
  }

  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-picker-search-input {
    margin-top: 16px;
  }

  .rs-picker-tag .rs-picker-toggle {
    margin-top: 10px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper {
    margin-bottom: 10px;
  }

  .dragAndDropImageContainer {
    display: flex;
    touch-action: none;
    width: 99.2%;
    margin: 1rem auto;
  }

  .dropZoneImage {
    flex: 1;
    height: 88.5rem;
  }

  .dropZoneImage.image {
    margin-right: 10px;
    margin-left: 10px;
  }

  .grid-item-image {
    width: 205px;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-image:hover {
    cursor: move;
  }

  .grid-item-image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .dragAndDropTextContainer {
    display: flex;
    touch-action: none;
    width: 64rem;
    margin: 1rem auto;
  }

  .dropZoneText {
    flex: 1;
    height: 56rem;
  }

  .dropZoneText.text {
    margin-right: 10px;
  }

  .grid-item-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .relatedComponentStyle-text,
  .imageSelectorStyle-text {
    max-height: 42em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-text-0,
  .imageSelectorStyle-text-0 {
    max-height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-text-0,
    .imageSelectorStyle-text-0  {
      max-height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-0,
  .imageSelectorStyle-image-0 {
    height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-0,
    .imageSelectorStyle-image-0  {
      height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-s,
  .imageSelectorStyle-image-s {
    height: 19em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-s,
    .imageSelectorStyle-image-s,
    .relatedComponentStyle-image-m,
    .imageSelectorStyle-image-m,
    .relatedComponentStyle-image-l,
    .imageSelectorStyle-image-l,
    .relatedComponentStyle-image-xl,
    .imageSelectorStyle-image-xl {
      height: 22em !important;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll !important;
      width: 100%;
      border: none;
    }

    .dropZoneImage {
      flex: 1;
      height: 29.5rem;
    }
  }

  .relatedComponentStyle-image-m,
  .imageSelectorStyle-image-m {
    height: 35.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-l,
  .imageSelectorStyle-image-l {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-xl,
  .imageSelectorStyle-image-xl {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .bar {
    background-color: #f0f0f0;
    min-height: 2.5em;
  }

  .sidebar-0 {
    margin-top: 1px;
  }

  .bar-0 {
    margin-top: 1px;
    background-color: none;
  }

  .rs-btn {
    color: #4d6cef;
    margin-bottom: 6px;
  }

  .rs-panel-bordered {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 15px;
    width: 95.2%;
  }

  .rs-panel-bordered-image {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    margin-left: 0px;
    display: inline-block;
    width: 100%;
  }

  .centered-image-added{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cfdcf4;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    font-size: 13px;
    color: #0b2497;
    font-weight: bold;
  }

  .textLayout {
    margin-bottom: 11px;
    text-align: justify;
  }

  .rs-panel-height {
    min-height: 45px;
  }

  .relatedComponentCheck {
    margin: 0 0px 0 10px !important;
    margin-top: 6px !important;
  }

  .ql-container {
    background-color: white;
    font-size: 16px;
    height: 26rem;
  }

  .ql-toolbar.ql-snow {
    background-color: #e9e9e9;
  }

  .terms {
    margin: 50px 0 10px;
    text-align: center;
  }

  .terms-container {
    display: inline-block;
    text-align: left;

    .form-error {
      margin-left: -20px;
    }
  }

  .terms + .terms {
    margin-top: 0;
  }

  .length-counter {
    font-size: 0.8em;
    font-style: italic;
    margin-top: -5px;
  }

  .length-counter + .form-error,
  .submit + .form-error {
    margin-top: -10px;
  }

  input + .length-counter {
    margin-top: -15px;
  }

  .submitting-status {
    text-align: center;
    vertical-align: middle;

    .loading {
      margin-right: 8px;
    }
  }
}

.campaign-form-medium {
  max-width: 992px;
  margin: 50px auto;
  .base-font;
  line-height: 135%;

  form {
    max-width: 950px;
    margin: 0 auto;
  }

  .image-preview {
    .field-theme;
    max-width: 100%;
  }

  h1 {
    .h1;
  }

  h2,
  .view-title {
    text-transform: uppercase;
    font-size: 24px;
    color: @brand-blue;
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  h3,
  .module-title {
    .h3;
  }

  .module-title {
    margin: 10px 0 20px;
    text-align: left;
  }

  .header-image-container {
    margin: 30px auto;
  }

  label,
  .form-group-label {
    .label;
    display: inline-block;
    text-align: left;
    white-space: normal;
  }

  .cols1 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 100%;
    }
  }

  .cols2 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 50%;
    }
  }

  .cols3 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: calc(100%/3);
    }
  }

  .cols4 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/4);
    }
  }

  .cols5 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/5);
    }
  }

  @media screen and (max-width:392px){
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 100%;
      }
    }
  }

  @media (min-width:393px) and (max-width:580px) {
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 50%;
      }
    }
  }

  .builder.form-group label {
    display: inline-block;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin: 0;
  }

  .field-list {
    position: relative;
    margin-top: 20px;
  }

  .field-list + .field-list {
    border-top: 1px dashed @color-border;
    padding-top: 40px;

    .repeat-delete-module {
      margin-top: 40px;
    }
  }

  .relatedComponentInput,
  .imageSelectorInput {
    width: 4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #dfe1de;
  }

  .rs-panel-body, .rs-panel-header {
    padding: 10px;
    line-height: 19px;
  }

  .rs-btn-toolbar{
    text-align: right;
  }

  #sequenceToolbar{
    text-align: left;
  }

  #sequenceIcon.rs-icon {
    background-color: #d3d3d7dd;
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-height: 60px;
    margin-bottom: 25px;
  }

  *.rs-picker:not(.rs-picker-disabled):hover {
    border: 2px solid #005fcc;
    border-radius: 2px;
  }

  *.ant-pagination-total-text {
    margin-right: 30px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-tag {
    margin-top: 16px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
  }

  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-picker-search-input {
    margin-top: 16px;
  }

  .rs-picker-tag .rs-picker-toggle {
    margin-top: 10px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper {
    margin-bottom: 10px;
  }

  .dragAndDropImageContainer {
    display: flex;
    touch-action: none;
    width: 99.2%;
    margin: 1rem auto;
  }

  .dropZoneImage {
    flex: 1;
    height: 88.5rem;
  }

  .grid-item-image {
    width: 217px;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-image:hover {
    cursor: move;
  }

  .grid-item-image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .dragAndDropTextContainer {
    display: flex;
    touch-action: none;
    width: 64rem;
    margin: 1rem auto;
  }

  .dropZoneText {
    flex: 1;
    height: 56rem;
  }

  .dropZoneText.text {
    margin-right: 10px;
  }

  .grid-item-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .relatedComponentStyle-text,
  .imageSelectorStyle-text {
    max-height: 42em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-text-0,
  .imageSelectorStyle-text-0 {
    max-height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-text-0,
    .imageSelectorStyle-text-0  {
      max-height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-0,
  .imageSelectorStyle-image-0 {
    height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-0,
    .imageSelectorStyle-image-0  {
      height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-s,
  .imageSelectorStyle-image-s {
    height: 19em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-s,
    .imageSelectorStyle-image-s,
    .relatedComponentStyle-image-m,
    .imageSelectorStyle-image-m,
    .relatedComponentStyle-image-l,
    .imageSelectorStyle-image-l,
    .relatedComponentStyle-image-xl,
    .imageSelectorStyle-image-xl {
      height: 22em !important;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll !important;
      width: 100%;
      border: none;
    }

    .dropZoneImage {
      flex: 1;
      height: 29.5rem;
    }
  }

  .relatedComponentStyle-image-m,
  .imageSelectorStyle-image-m {
    height: 36em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-l,
  .imageSelectorStyle-image-l {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-xl,
  .imageSelectorStyle-image-xl {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .bar {
    background-color: #f0f0f0;
    min-height: 2.5em;
  }

  .sidebar-0 {
    margin-top: 1px;
  }

  .bar-0 {
    margin-top: 1px;
    background-color: none;
  }

  .rs-btn {
    color: #4d6cef;
    margin-bottom: 6px;
  }

  .rs-panel-bordered {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 15px;
    width: 96.2%;
  }

  .rs-panel-bordered-image {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    margin-left: 0px;
    display: inline-block;
    width: 100%;
  }

  .centered-image-added{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cfdcf4;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    font-size: 13px;
    color: #0b2497;
    font-weight: bold;
  }

  .textLayout {
    margin-bottom: 11px;
    text-align: justify;
  }

  .rs-panel-height {
    min-height: 45px;
  }

  .relatedComponentCheck {
    margin: 0 0px 0 10px !important;
    margin-top: 6px !important;
  }

  .ql-container {
    background-color: white;
    font-size: 16px;
    height: 26rem;
  }

  .ql-toolbar.ql-snow {
    background-color: #e9e9e9;
  }

  .terms {
    margin: 50px 0 10px;
    text-align: center;
  }

  .terms-container {
    display: inline-block;
    text-align: left;

    .form-error {
      margin-left: -20px;
    }
  }

  .terms + .terms {
    margin-top: 0;
  }

  .length-counter {
    font-size: 0.8em;
    font-style: italic;
    margin-top: -5px;
  }

  .length-counter + .form-error,
  .submit + .form-error {
    margin-top: -10px;
  }

  input + .length-counter {
    margin-top: -15px;
  }

  .submitting-status {
    text-align: center;
    vertical-align: middle;

    .loading {
      margin-right: 8px;
    }
  }
}

.campaign-form-large {
  max-width: 1290px;
  margin: 50px auto;
  .base-font;
  line-height: 135%;

  form {
    max-width: 1280px;
    margin: 0 auto;
  }

  .image-preview {
    .field-theme;
    max-width: 100%;
  }

  h1 {
    .h1;
  }

  h2,
  .view-title {
    text-transform: uppercase;
    font-size: 24px;
    color: @brand-blue;
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  h3,
  .module-title {
    .h3;
  }

  .module-title {
    margin: 10px 0 20px;
    text-align: left;
  }

  .header-image-container {
    margin: 30px auto;
  }

  label,
  .form-group-label {
    .label;
    display: inline-block;
    text-align: left;
    white-space: normal;
  }

  .cols1 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 100%;
    }
  }

  .cols2 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 50%;
    }
  }

  .cols3 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: calc(100%/3);
    }
  }

  .cols4 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/4);
    }
  }

  .cols5 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/5);
    }
  }

  @media screen and (max-width:392px){
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 100%;
      }
    }
  }

  @media (min-width:393px) and (max-width:580px) {
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 50%;
      }
    }
  }

  .builder.form-group label {
    display: inline-block;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin: 0;
  }

  .field-list {
    position: relative;
    margin-top: 20px;
  }

  .field-list + .field-list {
    border-top: 1px dashed @color-border;
    padding-top: 40px;

    .repeat-delete-module {
      margin-top: 40px;
    }
  }

  .relatedComponentInput,
  .imageSelectorInput {
    width: 4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #dfe1de;
  }

  .rs-panel-body, .rs-panel-header {
    padding: 10px;
    line-height: 19px;
  }

  .rs-btn-toolbar{
    text-align: right;
  }

  #sequenceToolbar{
    text-align: left;
  }

  #sequenceIcon.rs-icon {
    background-color: #d3d3d7dd;
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-height: 60px;
    margin-bottom: 25px;
  }

  *.rs-picker:not(.rs-picker-disabled):hover {
    border: 2px solid #005fcc;
    border-radius: 2px;
  }

  *.ant-pagination-total-text {
    margin-right: 30px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-tag {
    margin-top: 16px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
  }

  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-picker-search-input {
    margin-top: 16px;
  }

  .rs-picker-tag .rs-picker-toggle {
    margin-top: 10px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper {
    margin-bottom: 10px;
  }

  .dragAndDropImageContainer {
    display: flex;
    touch-action: none;
    width: 99.2%;
    margin: 1rem auto;
  }

  .dropZoneImage {
    flex: 1;
    height: 88.5rem;
  }

  .dropZoneImage.image {
    margin-right: 3px;
  }

  .grid-item-image {
    width: 217px;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-image:hover {
    cursor: move;
  }

  .grid-item-image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .dragAndDropTextContainer {
    display: flex;
    touch-action: none;
    width: 64rem;
    margin: 1rem auto;
  }

  .dropZoneText {
    flex: 1;
    height: 56rem;
  }

  .dropZoneText.text {
    margin-right: 10px;
  }

  .grid-item-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .relatedComponentStyle-text,
  .imageSelectorStyle-text {
    max-height: 42em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-text-0,
  .imageSelectorStyle-text-0 {
    max-height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-text-0,
    .imageSelectorStyle-text-0  {
      max-height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-0,
  .imageSelectorStyle-image-0 {
    height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-0,
    .imageSelectorStyle-image-0  {
      height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-s,
  .imageSelectorStyle-image-s {
    height: 19em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-s,
    .imageSelectorStyle-image-s,
    .relatedComponentStyle-image-m,
    .imageSelectorStyle-image-m,
    .relatedComponentStyle-image-l,
    .imageSelectorStyle-image-l,
    .relatedComponentStyle-image-xl,
    .imageSelectorStyle-image-xl {
      height: 22em !important;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll !important;
      width: 100%;
      border: none;
    }

    .dropZoneImage {
      flex: 1;
      height: 29.5rem;
    }
  }

  .relatedComponentStyle-image-m,
  .imageSelectorStyle-image-m {
    height: 35.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-l,
  .imageSelectorStyle-image-l {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-xl,
  .imageSelectorStyle-image-xl {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .bar {
    background-color: #f0f0f0;
    min-height: 2.5em;
  }

  .sidebar-0 {
    margin-top: 1px;
  }

  .bar-0 {
    margin-top: 1px;
    background-color: none;
  }

  .rs-btn {
    color: #4d6cef;
    margin-bottom: 6px;
  }

  .rs-panel-bordered {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 15px;
    width: 97.2%;
  }

  .rs-panel-bordered-image {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    margin-left: 0px;
    display: inline-block;
    width: 100%;
  }

  .centered-image-added{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cfdcf4;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    font-size: 13px;
    color: #0b2497;
    font-weight: bold;
  }

  .textLayout {
    margin-bottom: 11px;
    text-align: justify;
  }

  .rs-panel-height {
    min-height: 45px;
  }

  .relatedComponentCheck {
    margin: 0 0px 0 10px !important;
    margin-top: 6px !important;
  }

  .ql-container {
    background-color: white;
    font-size: 16px;
    height: 26rem;
  }

  .ql-toolbar.ql-snow {
    background-color: #e9e9e9;
  }

  .terms {
    margin: 50px 0 10px;
    text-align: center;
  }

  .terms-container {
    display: inline-block;
    text-align: left;

    .form-error {
      margin-left: -20px;
    }
  }

  .terms + .terms {
    margin-top: 0;
  }

  .length-counter {
    font-size: 0.8em;
    font-style: italic;
    margin-top: -5px;
  }

  .length-counter + .form-error,
  .submit + .form-error {
    margin-top: -10px;
  }

  input + .length-counter {
    margin-top: -15px;
  }

  .submitting-status {
    text-align: center;
    vertical-align: middle;

    .loading {
      margin-right: 8px;
    }
  }
}

.campaign-form-full-width {
  max-width: 100%;
  margin: 50px auto;
  .base-font;
  line-height: 135%;

  form {
    max-width: 100%;
    margin: 0 auto;
  }

  .image-preview {
    .field-theme;
    max-width: 100%;
  }

  h1 {
    .h1;
  }

  h2,
  .view-title {
    text-transform: uppercase;
    font-size: 24px;
    color: @brand-blue;
    margin: 0 0 15px 0;
    font-weight: 400;
  }

  h3,
  .module-title {
    .h3;
  }

  .module-title {
    margin: 10px 0 20px;
    text-align: left;
  }

  .header-image-container {
    margin: 30px auto;
  }

  label,
  .form-group-label {
    .label;
    display: inline-block;
    text-align: left;
    white-space: normal;
  }

  .cols1 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 100%;
    }
  }

  .cols2 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: 50%;
    }
  }

  .cols3 {
    .checkbox-wrap,
    .radio-wrap,
    .custom-radio-label {
      width: calc(100%/3);
    }
  }

  .cols4 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/4);
    }
  }

  .cols5 {
    .checkbox-wrap,
    .radio-wrap {
      width: calc(100%/5);
    }
  }

  @media screen and (max-width:392px){
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 100%;
      }
    }
  }

  @media (min-width:393px) and (max-width:580px) {
    .cols3, .cols4, .cols5 {
      .checkbox-wrap,
      .radio-wrap,
      .custom-radio-label {
        width: 50%;
      }
    }
  }

  .builder.form-group label {
    display: inline-block;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 18px;
    color: #fff;
    margin: 0;
  }

  .field-list {
    position: relative;
    margin-top: 20px;
  }

  .field-list + .field-list {
    border-top: 1px dashed @color-border;
    padding-top: 40px;

    .repeat-delete-module {
      margin-top: 40px;
    }
  }

  .relatedComponentInput,
  .imageSelectorInput {
    width: 4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #dfe1de;
  }

  .rs-panel-body, .rs-panel-header {
    padding: 10px;
    line-height: 19px;
  }

  .rs-btn-toolbar{
    text-align: right;
  }

  #sequenceToolbar{
    text-align: left;
  }

  #sequenceIcon.rs-icon {
    background-color: #d3d3d7dd;
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
    border-radius: 0;
    min-height: 60px;
    margin-bottom: 25px;
  }

  *.rs-picker:not(.rs-picker-disabled):hover {
    border: 2px solid #005fcc;
    border-radius: 2px;
  }

  *.ant-pagination-total-text {
    margin-right: 30px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-tag {
    margin-top: 16px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
  }

  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper .rs-picker-search-input {
    margin-top: 16px;
  }

  .rs-picker-tag .rs-picker-toggle {
    margin-top: 10px;
  }

  .rs-picker-tag .rs-picker-toggle.rs-btn~.rs-picker-tag-wrapper {
    margin-bottom: 10px;
  }

  .dragAndDropImageContainer {
    display: flex;
    touch-action: none;
    width: 99.2%;
    margin: 1rem auto;
  }

  .dropZoneImage {
    flex: 1;
    height: 88.5rem;
  }

  .dropZoneImage.image {
    margin-right: 3px;
  }

  .grid-item-image {
    width: 217px;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-image:hover {
    cursor: move;
  }

  .grid-item-image-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .dragAndDropTextContainer {
    display: flex;
    touch-action: none;
    width: 64rem;
    margin: 1rem auto;
  }

  .dropZoneText {
    flex: 1;
    height: 56rem;
  }

  .dropZoneText.text {
    margin-right: 10px;
  }

  .grid-item-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .relatedComponentStyle-text,
  .imageSelectorStyle-text {
    max-height: 42em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-text-0,
  .imageSelectorStyle-text-0 {
    max-height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-text-0,
    .imageSelectorStyle-text-0  {
      max-height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-0,
  .imageSelectorStyle-image-0 {
    height: 3em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-0,
    .imageSelectorStyle-image-0  {
      height: 5em;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      border: none;
    }
  }

  .relatedComponentStyle-image-s,
  .imageSelectorStyle-image-s {
    height: 19em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  @media screen and (max-width:580px){
    .relatedComponentStyle-image-s,
    .imageSelectorStyle-image-s,
    .relatedComponentStyle-image-m,
    .imageSelectorStyle-image-m,
    .relatedComponentStyle-image-l,
    .imageSelectorStyle-image-l,
    .relatedComponentStyle-image-xl,
    .imageSelectorStyle-image-xl {
      height: 22em !important;
      line-height: 1em;
      overflow-x: hidden;
      overflow-y: scroll !important;
      width: 100%;
      border: none;
    }

    .dropZoneImage {
      flex: 1;
      height: 29.5rem;
    }
  }

  .relatedComponentStyle-image-m,
  .imageSelectorStyle-image-m {
    height: 35.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-l,
  .imageSelectorStyle-image-l {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    border: none;
  }

  .relatedComponentStyle-image-xl,
  .imageSelectorStyle-image-xl {
    height: 52.5em;
    line-height: 1em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    border: none;
  }

  .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .bar {
    background-color: #f0f0f0;
    min-height: 2.5em;
  }

  .sidebar-0 {
    margin-top: 1px;
  }

  .bar-0 {
    margin-top: 1px;
    background-color: none;
  }

  .rs-btn {
    color: #4d6cef;
    margin-bottom: 6px;
  }

  .rs-panel-bordered {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 15px;
    width: 98.2%;
  }

  .rs-panel-bordered-image {
    background-color: #f9f9f6;
    margin-bottom: 5px;
    margin-left: 0px;
    display: inline-block;
    width: 100%;
  }

  .centered-image-added{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cfdcf4;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    font-size: 13px;
    color: #0b2497;
    font-weight: bold;
  }

  .textLayout {
    margin-bottom: 11px;
    text-align: justify;
  }

  .rs-panel-height {
    min-height: 45px;
  }

  .relatedComponentCheck {
    margin: 0 0px 0 10px !important;
    margin-top: 6px !important;
  }

  .ql-container {
    background-color: white;
    font-size: 16px;
    height: 26rem;
  }

  .ql-toolbar.ql-snow {
    background-color: #e9e9e9;
  }

  .terms {
    margin: 50px 0 10px;
    text-align: center;
  }

  .terms-container {
    display: inline-block;
    text-align: left;

    .form-error {
      margin-left: -20px;
    }
  }

  .terms + .terms {
    margin-top: 0;
  }

  .length-counter {
    font-size: 0.8em;
    font-style: italic;
    margin-top: -5px;
  }

  .length-counter + .form-error,
  .submit + .form-error {
    margin-top: -10px;
  }

  input + .length-counter {
    margin-top: -15px;
  }

  .submitting-status {
    text-align: center;
    vertical-align: middle;

    .loading {
      margin-right: 8px;
    }
  }
}

// TODO: move to modules
.image-uploader {

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  input + label {
    display: inline-block;
    color: #fff;
    background-color: #979797;
    padding: 15px 25px;
    border-radius: @form-field-border-radius;
    transition: @transition-default;
    cursor: pointer;
  }

  input:focus + label,
  input + label:hover {
    background-color: darken(#979797, 20%);
  }

  .uploading-area {
    position: relative;
    background-color: #dcdcdc;
    padding: 50px;
    margin: 5px auto;
    text-align: center;
  }

}

.submission-data {

  .submission-count {
    font-size: 18px;

    > span {
      color: @brand-blue;
    }
  }

  .submission-info {
    display: flex;
    padding-bottom: 10px;

    table {
      width: 50%;
      background-color: #e8ecf9;

      &:first-child {
        margin-right: 1px;
        border-radius: 7px 0px 0px 7px;
      }
      &:last-child {
        margin-left: 1px;
        border-radius: 0px 7px 7px 0px;
      }

      tr {
        &:first-child td {
          padding-top: 12px;
        }

        &:last-child td {
          padding-bottom: 12px;
        }

        td {
          padding-left: 15px;
          padding-bottom: 7px;

          &:first-child {
            width: 20%;
            font-weight: bold;
          }

          &:last-child {
            width: 80%;
          }
        }
      }
    }
  }

  .submission-info.list-view {
    table {
      background-color: transparent !important;

      tr td {
        padding-left: 0px;
      }
    }
  }

  .info-bar {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #fff;

    .submissions-count {
      margin: 0;
    }
  }

  .short-content,
  .long-content {
    white-space: pre-wrap;
  }

  .submitted-data {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #bcbab8;

    .user-entry {
      margin-top: 20px;

      .list-unstyled {
        list-style: none;
        padding: 0;
      }
    }

    .submitted-content {
      width: 100%;

      .field-wrapper {
        border-top: 1px solid @form-field-border-color;

        &:first-of-type {
          border-top: none;
        }
      }
      .field {
        padding: 10px;
      }
      .field-name {
        width: 250px;
        font-weight: bold;
        padding: 10px;
      }
      mark {
        background: #ff0;
        padding: 0;
      }
    }

    .finalise {
      top: 0;
      left: 0;
      font-size: 20px;
      color: grey;
      margin-top: 0;
      margin-right: 10px;
    }

    .data-table.full-border {
      table {
        td, th {
          cursor: default;
          border: 1px solid #dedbd7;
        }
      }
    }
  }

  .revision-div {
    background: #fff;
    border-radius: 3px;
    padding: 10px 0;
  }

  .toggle-btn-container {
    text-align: right;
    padding: 10px 0;
  }

  .campaign-submission-title {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .sth, .std {
      color: #4d4d4d;
      font-family: @font-family-sans;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.13px;
      min-width: 92px;
      margin-right: 10px;
    }

    .sth {
      font-weight: 600;
    }

    .str {
      padding-right: 30px;
    }
  }
  .collapse-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .collapse-action {
    button {
      text-decoration: none;
      border-radius: 4px;

      &:focus {
        outline: none;
        outline-offset: none;
      }

      &:hover {
        background-color: #eeeeee;
      }

      &:before {
        content: 'Show';
        font-size: 14px;
        color: #4d6cef;
      }

      &.active {
        &:before {
          content: 'Hide';
        }
      }
    }
  }

  .submissions-list {
    padding: 10px 20px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;

    .glyphicon-refresh {
      color: grey;
    }
  }

  .paginator-tfooter {
    margin-top: 0;
    padding-bottom: 10px;
  }
}
