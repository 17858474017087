.form-builder {
  .layout-item {
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;

    .options {
      width: 230px;
      text-align: right;
    }

    .glyphicon,
    .confirm-delete {
      margin-left: 12px;
    }
  }

  .movable .layout-item:nth-child(2) {
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 20px;
    border-top: 1px solid #dedede;
  }

  &.layout-order {
    .navigation-button.accent {
      display: block;
      margin: 30px auto 10px;
      background: transparent;

      &:hover {
        background: transparent;
      }
    }
  }
}
