.base-font {
  font-family: @font-family-sans;
  font-weight: 300;
  font-size: 18px;
  color: @brand-dark-gray1;
  line-height: 1.2em;
}

.h1, .h2, .h3, .h4, .h5 {
  .base-font;
  text-align: center;
}

.h1 {
  font-size: 42px;
  margin: 5px auto;
}

.h2 {

}

.h3 {
  color: @brand-light-gray3;
  font-size: 22px;
}

.h4 {

}

.h5 {

}
