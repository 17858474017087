.basic-page {
  display: flex;
  flex-direction: column;
  background-color: @brand-light-gray1;

  header {
    background-color: #fff;
    flex: 0 0 auto;
  }

  nav {
    flex: 0 0 auto;
  }

  .message-container {
    text-align: center;
  }

  .error-message {
    margin-top: 80px;
    margin-bottom: 50px;
    font-size: 28px;
    line-height: 1.2em;
    text-align: center;
    color: #949494;
  }

  .default-button {
    display: inline-block;
    font-family: @font-family-sans;
    font-weight: 300;
    line-height: 1.2em;
    border: 1px solid @form-field-border-color;
    border-radius: @form-field-border-radius;
    padding: 15px;
    font-size: 25px;
    color: #fff;
    margin: 15px auto 50px;
    min-height: @form-field-height;
    transition: @transition-default;
    background-color: @brand-blue;
  }

  main {
    flex: 1 0 auto;
  }

  .tabs {
    background-color: #fff;
  }

  footer {
    text-align: center;
  }

  .copyright {
    color: #979797;
    font-family: @font-family-sans;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.2em;
    margin: 0 auto;
    padding: 30px floor((@grid-gutter-width / 2));
  }
}
