.breadcrumb-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.breadcrumb-container {
  list-style: none;
  overflow: hidden;
  color: #fff;
  height: 38px;
  line-height: 38px;
  font-size: 15px;
  padding-left: 0;
  padding-right: 6px;
  margin-bottom: 0;

  > li {
    float: left;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;

    .nav-link {
      position: relative;
      float: left;
      &:after, &:before {
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-width: 50px 0 50px 30px;
        border-style: solid;
        border-color: transparent;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        left: 100%;
      }
      &:after {
        border-left-color: @brand-dark-gray2;
        z-index: 2;
      }
      &:before {
        border-left-color: @brand-light-gray1;
        margin-left: 6px;
        z-index: 1;
      }
    }
    &:first-child {
      border-radius: 18px;
    }

    // ORANGE SKIN
    &.orange {
      background-color: @skin-color-orange;
      .button-rollover(@skin-color-orange);

      .nav-link:after {
        border-left-color: @skin-color-orange;
      }
      &:hover {
        .nav-link:after {
          border-left-color: darken(@skin-color-orange, 10);
        }
      }
      &:active {
        .nav-link:after {
          border-left-color: darken(@skin-color-orange, 20);
        }
      }
    }

    // BLUE SKIN
    &.blue {
      background-color: @brand-blue;
      .button-rollover(@brand-blue);

      .nav-link:after {
        border-left-color: @brand-blue;
      }
      &:hover {
        .nav-link:after {
          border-left-color: darken(@brand-blue, 10);
        }
      }
      &:active {
        .nav-link:after {
          border-left-color: darken(@brand-blue, 20);
        }
      }
    }

    // GREY SKIN
    &.grey {
      background-color: @brand-dark-gray2;
      .button-rollover(@brand-dark-gray2);

      .nav-link:after {
        border-left-color: @brand-dark-gray2;
      }
      &:hover {
        .nav-link:after {
          border-left-color: darken(@brand-dark-gray2, 10);
        }
      }
      &:active {
        .nav-link:after {
          border-left-color: darken(@brand-dark-gray2, 20);
        }
      }
    }

    &.disabled {
      color: #b5b7bb;
      cursor: default;
      .button-rollover(@color-disabled);

      .nav-link:after {
        border-left-color: @color-disabled;
      }
      &:hover {
        .nav-link:after {
          border-left-color: darken(@color-disabled, 10);
        }
      }
      &:active {
        .nav-link:after {
          border-left-color: darken(@color-disabled, 20);
        }
      }
    }
  }
}

.rs-breadcrumb {
  margin-top: 20px;
  font-size: 15px;
}
