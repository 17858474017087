.profile-show {
  padding: 15px;
  .section {
    margin-bottom: floor((@grid-gutter-width / 2));
    .title, .data {
      .form-font;
      width: 100%;
      text-align: center;
    }

    .title {
      font-weight: 500;
      font-size: 1.8rem;
    }
  }
}
