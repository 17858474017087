.back-to {
  margin-top: 40px;

  a {
    color: @brand-dark-gray2;
    text-decoration: none;
    cursor: pointer;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2px;
  }

  .glyphicon {
    font-size: 11px;
    padding: 6px;
    background-color: #dedede;
    border-radius: 4px;
    margin-right: 12px;

    &:hover {
      background-color: darken(#dedede, 10%);
    }
  }
}
