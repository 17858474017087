.modal {
  overflow: auto;
  display: block;

  .campaign-form {
    margin: 0 auto;
  }

  .modal-footer {
    button:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.clickable {
  cursor: pointer;
}
