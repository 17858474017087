.details-editor.first.panel.left-displayed {
  padding: 30px 22px 30px 22px;
  overflow-y: scroll;
}

.details-editor {
  h4 > input[type=text] {
    border: none;
    border-bottom: 1px solid #e8e8e8;
    outline: none;
    padding-bottom: 5px;
    width: 100%;
    max-width: 650px;
    color: @brand-blue;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  textarea[name=emailBody] {
    height: 150px;
  }
}
*.rs-btn-toolbar {
  display: flex;
  justify-content: center;
}

*.rs-btn-icon.rs-btn-md {
  padding-right: 1px;
}

*.rs-table-loading .rs-table-loader-wrapper {
  opacity: 0.8;
}

*.rs-table-body-info-wheel-area, .rs-table-body-wheel-area {
  cursor: pointer;
}

.rs-table-cell-header-sortable .rs-table-cell-content {
  background-color: #e6e6e6;
}

.rs-table-row-header .rs-table-cell {
  background-color: #e6e6e6 !important;
}

*.rs-table-bordered {
  border: #eb0a0a;
}

.rs-table-cell {
  background-color: #f0f0f0;
  border-bottom: 1px solid #dedede;
}

.rs-table-cell-content {
  white-space: normal;
  word-wrap: break-word;
  color: #333333;
}

.rs-table-row-header {
  background-color: #e6e6e6;
  color: #000;
}

.full-width-panel {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}

.finalise {
  top: 3px;
  left: -25px;
  font-size: 20px;
  color: @brand-blue;
  margin-top: 0;
}

.repeat-module {
  border: none;
  margin: 0 auto;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: @brand-primary;
  color: #fff;
  outline: none;
  transition: @transition-default;

  &:hover {
    background-color: darken(@brand-primary, 20%);
  }
}

.repeat-delete-module {
  position: absolute;
  color: #d01c34;
  top: -20px;
  right: 10px;
  cursor: pointer;
}

.editable-object-properties {
  .editable-object-properties-head {
    display: flex;

    button {
      background: none;
      height: 25px;
      margin-top: 30px;
    }
  }

  .glyphicon {
    border: none;
  }

  input {
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 10px;
    width: 400px;
    border: 1px solid #dedbd7;
  }

  input:first-child {
    width: 200px;
  }

  .custom-meta {
    display: grid;

    label {
      font-weight: normal;
    }
  }
}
