@import '~bootstrap/less/bootstrap';
@import '~react-quill/dist/quill.snow.css';
@import '~rsuite/dist/rsuite.min.css';
@import '~primereact/resources/themes/lara-light-blue/theme.css';
@import '~primeicons/primeicons.css';
@import '~antd/dist/reset.css';
@import (css) url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600');
@import 'variables';
@import 'mixins';
@import 'typo';
@import 'layout';
@import 'apps';
@import 'modules';
@import 'skins';
@import 'react-utility';
@import 'form';
@import 'pages';

html, body {
  height: 100%;

  &.embedded {
    overflow: hidden;
  }
}

body {
  overflow: auto;
  font-family: @font-family-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  text-align: center;
}

.spinning {
  animation: spin 1000ms infinite linear;
}

.fadein-appear {
  opacity: 0.01;
}

.fadein-appear.fadein-appear-enter {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.centered-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  .label {
    margin-top: 14px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
