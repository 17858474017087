.body-form.standard {
  background-color: @brand-blue;

  .submit-button {
    background-color: @brand-blue;
  }

  label.required:after,
  .form-group-label.required:after {
    color: @brand-blue;
  }

  .builder.form-group select,
  .builder.form-group input[type=text],
  .builder.form-group textarea {
    border-color: @brand-blue;
  }

  .repeat-module {
    background-color: @brand-blue;

    &:hover {
      background-color: darken(@brand-blue, 20%);
    }
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    border-color: @brand-blue;
  }

  .location-suggest .loading {
    color: @brand-blue;
  }
}
