.supplementary-image-selector {
  .image-card {
    position: relative;
    display: inline-block;

    input {
      position: absolute;
      top: 10px;
      left: 30px;
    }
  }

  span {
    font-size: 14px;
  }

  img {
    aspect-ratio: 16 / 9;
  }
}
