.modal-form-page {
  background-color: @brand-primary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    display: flex;
    width: 600px;
    justify-content: center;
    flex: 0 0 auto;
    padding: 50px 15px;

    @media (max-width: 650px) {
      width: 90%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .row {
    width: 100%;
  }

  .form {
    .form-font;
    padding: 90px 20%;

    &.with-footer {
      padding-bottom: 30px;
    }

    @media (max-width: 600px) {
      padding: 5%;
    }
  }

  .form-instructions {
    font-size: 14px;
    color: #98a1b3;
    margin: 50px auto 0;
    text-align: center;

    .form-link {
      font-size: 14px;
      color: @brand-dark-gray2;
      font-weight: 400;
      text-decoration: underline;
    }

    p.form-error {
      margin-top: 0;
    }
  }
}
