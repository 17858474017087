.mailer {
  &-overview {
    .recipients-table-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-summary {
    .mailer-subheader {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 30px;
    }

    .subtitle-id {
      min-width: 350px;
      margin-right: 20px;

      .title {
        color: #516ef0;
        font-weight: 400;
        margin: 2px 0;
      }
    }

    .block-overview {
      &.align-graph {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .display-labels {
          padding: 10px 15px;
          margin-left: 20px;
        }
        .graph-desc {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;

          &.active {
            .graph-dot {
              height: 20px;
              width: 20px;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }
}

.graph-dot {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  margin-right: 10px;
}

.graph-label {
  .int-label {
    font-size: 20px;
    font-weight: 800;
    margin-right: 10px;
  }
}
