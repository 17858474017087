.sliding-panel {
  position: relative;
  height: ~'calc(100vh - 257px)';
  min-height: 70vh;
  overflow-x: hidden;

  .panel-button {
    position: absolute;
    width: 200px;
    text-align: center;
    right: 0;
    padding-top: 5px;
    cursor: pointer;
    z-index: 5;
    top: 0;
    height: 56px;
    color: #b1b1b1;
    background-color: @brand-dark-gray2;
  }

  .panel {
    padding-top: 7px;
    padding-bottom: 17px;
    padding-left: 22px;
    padding-right: 22px;
    position: absolute;
    border: none;
    border-radius: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    transition: left 0.3s;

    .panel-title {
      height: 56px;
      line-height: 56px;
      background-color: @brand-dark-gray2;
      color: #fff;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      text-transform: uppercase;
    }

    .overflow {
      overflow-y: scroll;
      height: ~'calc(100% - 56px)';
    }

    .panel-title-spacer {
      height: 56px;
    }

    .actions-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .first.panel {
    background-color: #fff;
    left: -50%;
    z-index: 2;
  }

  .second.panel {
    background-color: #d5d5d5;
    left: ~'calc(-50% + 20px)';
    z-index: 1;
  }

  .third.panel {
    left: 100%;
    background-color: #fff;
    z-index: 3;
  }

  .panel.left-displayed {
    left: 0;

  }

  .panel.right-displayed {
    left: 50%;

  }
}

.onoffswitch {
  position: relative; width: 44px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
  margin: 5px auto;
}

input[type="checkbox"].onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block; overflow: hidden; cursor: pointer;
  height: 12px; padding: 0; line-height: 12px;
  border: 0 solid #FFFFFF; border-radius: 20px;
  background-color: #9E9E9E;
}
.onoffswitch-label:before {
  content: '';
  display: block; width: 20px; margin: -4px;
  background: #FFFFFF;
  position: absolute; top: 0; bottom: 0;
  right: 28px;
  border-radius: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #42A5F5;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #42A5F5;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0;
  background-color: #2196F3;
  box-shadow: 3px 6px 18px 0 rgba(0, 0, 0, 0.2);
}
