.options {
  .glyphicon {
    padding: 4px;
  }
}

.glyphicon-trash {
  color: #0b0a0a;
}

.confirm-delete {
  font-family: @font-family-sans;
  color: #fff;
  padding: 6px 10px;
  background-color: @color-error;
  text-transform: uppercase;
  transition: none;

  &:hover {
    background-color: darken(@color-error, 10%);
  }
}

.glyphicon-refresh {
  &.small {
    font-size: @font-size-base;
  }

  &.medium {
    font-size: 20px;
  }

  &.large {
    font-size: 30px;
  }
}
