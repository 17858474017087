.table-component {
  table-layout: fixed;
  width: 100%;

  th {
    text-align: right;
    &:last-child {
      padding-right: 20px;
    }
  }

  .emphasized {
    font-weight: bold;
  }

  tr {
    height: 48px;
    &:not(:first-child) {
      &:nth-child(odd) {
        background-color: @brand-light-gray1;
      }
    }

    td {
      border-top: solid 1px;
      border-color: @brand-light-gray2;
      &:first-child {
        text-align: left;
        padding-left: 20px;
        border-right: solid 1px;
        border-color: @brand-light-gray2;
      }
      &:not(:first-child) {
        text-align: right;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
}

