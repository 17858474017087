.user-profile-page {
  .form {
    max-width: 500px;
    padding: 50px 0;
    margin: 0 auto;

    input[type="checkbox"] {
      margin-left: 0;
    }

    .note {
      font-style: italic;
      font-size: 13px;
      margin-top: -10px;
      padding-left: 2px;
    }

    .select-container + .form-error {
        margin-top: -10px
    }
  }
}

.users-overview {
  .user-details {
    &-actions {
      color: #4d4d4d;
      font-size: 16px;
      font-weight: 600;
      margin: 31px 0;
      display: flex;
      justify-content: space-between;

      .glyphicon {
        margin: 0 15px;
        &-remove-sign {
          color: #d0021b;
        }
        &-ok-sign {
          color: #8cb600;
        }
        &-tash {
          margin-right: 0;
        }
      }
    }
    table {
      border-collapse: initial;

      tr {
        &:last-child {
          th:first-child {
            border-bottom-left-radius: 6px;
          }
          td:last-child {
            border-bottom-right-radius: 6px;
          }
        }
        &:first-child {
          td:last-child {
            border-top-right-radius: 6px;
          }
          th:first-child {
            border-top-left-radius: 6px;
          }
        }
      }
    }

    th,
    td {
      padding: 15px 10px;
      cursor: default;
    }

    th {
      background-color: #f1f1f1;
      color: #4d4d4d;
      font-weight: 600;
      font-size: 16px;
      width: 185px;
    }
  }

  .submissions-count {
    padding-top: 15px;
  }
}
