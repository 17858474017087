.search-bar-wrap {
  padding: 20px 0;
}

.search-bar-component {
  padding: 20px 0;
  display: flex;
}

.search-bar {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  background-color: @brand-white;
  border: 1px solid #dedede;
  border-radius: 4px;
  vertical-align: top;
  position: relative;

  span.glyphicon {
    font-size: 16px;
    color: #4d4d4d;
    display: inline-block;
    vertical-align: middle;
    margin: -4px 10px 0;
  }

  span.glyphicon.loading {
    position: absolute;
    right: 0;
    top: 14px;
  }

  input {
    display: inline-block;
    border: none;
    outline: none;
    background: transparent;
    height: 38px;
    padding-right: 35px;
  }
}
