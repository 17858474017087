.react-autosuggest__ {
  &container {
    position: relative;
    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .react-autosuggest__suggestions-container {
        display: block;
        position: absolute;
        top: @form-field-height;
        width: 100%;
        border: 1px solid @brand-blue;
        background-color: #fff;
        font-size: 16px;
        padding: 10px;
        border-bottom-left-radius: @form-field-border-radius;
        border-bottom-right-radius: @form-field-border-radius;
        z-index: 2;
        max-height: 300px;
        overflow-y: scroll;
      }
    }
  }

  &input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &suggestions-container {
    display: none;
  }

  &suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &suggestion {
    cursor: pointer;
    padding: 10px 15px;
    &--focused {
      background-color: #ddd;
    }
  }
}

.react-autosuggest__container + .form-error {
  margin-top: -10px;
}

.location-suggest {
  position: relative;
  .loading {
    position: absolute;
    right: 15px;
    top: 20px;
  }
}

.tagsContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: text;
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding: 0;

  .tagChoice {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    padding: 2px 20px 2px 7px;
    display: inline-block;
    float: left;
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    background-color: #fff;

    .remove {
      font-weight: 500;
      position: absolute;
      right: 7px;
      color: #999;
      cursor: pointer;
    }
  }
}

.description-autosuggest {
  .description {
    font-size: 12px;
    line-height: 1;
  }
}
